import React from "react";
import { Link } from "react-router-dom";
//import queryString from "query-string";
import { ErrorMessage, Loading } from "components";
import env from "../../../env";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { variables } from "../../../cssInJs";
//import MuiDialogTitle from "@material-ui/core/DialogTitle";
//import MuiDialogContent from "@material-ui/core/DialogContent";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
//import FileCopyIcon from "@material-ui/icons/FileCopy";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
//import LensIcon from "@material-ui/icons/Lens";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {
  //AppBar,
  //Tab,
  //Tabs,
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  //ListItemIcon,
  //TextField,
  Backdrop,
  Dialog,
  //List,
  //ListItem,
  //ListItemText,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  //Box,
  //DialogActions,
  //Grid,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  infoIcon: {
    cursor: "pointer",
    color: variables.colorBlue2,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const useSearchPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  textField: {
    //width: "-webkit-fill-available",
    //margin: theme.spacing(1),
    //width: `calc(33.3% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    width: `calc(33.3% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
  },
}));

const useDialogStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleFilter,
    //currentTab,
    activeStatus,
    inActiveStatus,
    deleteItems,
    //studioId,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          //color="inherit"
          //variant="subtitle1"
          component="div"
          style={{ fontSize: "18px" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          //variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontSize: "18px" }}
        >
          Newsletters
        </Typography>
      )}

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Create Belt Size" arrow>
          <Button component={Link} to={`/dashboard/news-letter/new`}>
            <AddIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Active" arrow>
          <Button onClick={activeStatus}>
            <CheckCircleOutlineIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="In Active" arrow>
          <Button onClick={inActiveStatus}>
            <RemoveCircleIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <Button onClick={deleteItems}>
            <DeleteIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Toolbar>
  );
};

const headCells = [
  {
    id: "Title",
    numeric: false,
    disablePadding: true,
    label: "Title",
    hasSort: true,
  },
  {
    id: "File",
    numeric: false,
    disablePadding: false,
    label: "File",
    hasSort: false,
  },
  {
    id: "StudioName",
    numeric: false,
    disablePadding: false,
    label: "Studio",
    hasSort: true,
  },
  {
    id: "Info",
    numeric: false,
    disablePadding: false,
    label: "Info",
    hasSort: false,
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    hasSort: true,
  },
];

const EnhancedTableHead = (props) => {
  const classes = useETHeadStyles();
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

const SearchPanel = (props) => {
  const classes = useSearchPanelStyles();
  const { register, searchType } = props;
  return (
    <form
      method="POST"
      noValidate=""
      className={classes.root}
      //onSubmit={handleSubmit(submit)}
    >
      <FormControl
        className={classes.textField}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password-cp">
          Search by Studio Name
        </InputLabel>

        <OutlinedInput
          id="outlined-adornment-password-cp"
          type="text"
          inputProps={{
            //...params.inputProps,
            defaultValue: "",
            autoComplete: "off",
            //autoComplete: "new-password",
          }}
          {...register("search", {
            onChange: (e) => {
              searchType();
            },
          })}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={190}
        />
      </FormControl>
    </form>
  );
};

const getStatusIcon = (status) => {
  if (status === 1) {
    return (
      <CheckCircleOutlineIcon fontSize="small" style={{ color: "green" }} />
    );
  } else if (status === 99) {
    return <RemoveCircleIcon fontSize="small" style={{ color: "red" }} />;
  }
  return null;
};

const SimpleDialog = (props) => {
  const { open, item, handleClose } = props;
  const classes = useDialogStyles();
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{item && item.title}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent dividers>
        <Typography gutterBottom>{item && item.description}</Typography>
      </MuiDialogContent>
    </Dialog>
  );
};

const schema = yup.object().shape({
  search: yup.string(),
});

export function AppManNewsletters() {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Title");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [trigger, setTrigger] = React.useState(0);
  const previousController = React.useRef();
  const timerController = React.useRef();
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [getApiResult, setGetApiResult] = React.useState();
  const [openInfo, setOpenInfo] = React.useState({
    open: false,
    item: null,
  });
  const {
    getValues,
    register,
    //setValue,
    //formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: { search: "" },
  });

  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getApiResult.appManages.map(
        (n) => n.appManagementId
      );
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    if (getApiResult.appManages && getApiResult.appManages.length > 0) {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenInfo = (item) => {
    openInfo.open = true;
    openInfo.item = item;
    setOpenInfo({
      ...openInfo,
    });
  };

  const handleClose = () => {
    openInfo.open = false;
    openInfo.item = null;
    setOpenInfo({
      ...openInfo,
    });
  };

  const handleChange = (event, appManagementId) => {
    const selectedIndex = selected.indexOf(appManagementId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, appManagementId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }, 500);
  };

  const activeStatus = async () => {
    if (!selected || selected.length <= 0) {
      return;
    }
    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/appmanage/activeStatus", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          selectedIds: selected,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      } else {
        setSelected([]);
        setTrigger(trigger + 1);
      }

      //setBackdropOpen(false);
      // timerController.current = setTimeout(() => {
      //   setBackdropOpen(false);
      // }, 800);
    } catch (error) {
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  };

  const inActiveStatus = async () => {
    if (!selected || selected.length <= 0) {
      return;
    }
    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/appmanage/inactivestatus", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          selectedIds: selected,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      } else {
        setSelected([]);
        setTrigger(trigger + 1);
      }

      //setBackdropOpen(false);
      // timerController.current = setTimeout(() => {
      //   setBackdropOpen(false);
      // }, 800);
    } catch (error) {
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  };

  const deleteItems = async () => {
    
    if (!selected || selected.length <= 0) {
      return;
    }
    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/appmanage/deleteitems", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          selectedIds: selected,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      } else {
        setSelected([]);
        setTrigger(trigger + 1);
      }

      //setBackdropOpen(false);
      // timerController.current = setTimeout(() => {
      //   setBackdropOpen(false);
      // }, 800);
    } catch (error) {
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/appmanage/getappmanageitems",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              ...getValues(),
              page: page,
              rowsPerPage: rowsPerPage,
              order: order,
              orderBy: orderBy,
              type: 2,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();
        
        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
          setLoading(false);
          setBackdropOpen(false);
          return;
        }

        if (data.results) {
          const _result = { ...data.results };
          setGetApiResult({
            ..._result,
          });
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [trigger]);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <SimpleDialog {...openInfo} handleClose={handleClose} />

      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleFilter={handleFilter}
          activeStatus={activeStatus}
          inActiveStatus={inActiveStatus}
          deleteItems={deleteItems}
        />

        {filterOpen && (
          <SearchPanel register={register} searchType={searchType} />
        )}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={getApiResult.appManages.length}
            />

            <TableBody>
              {getApiResult.appManages.map((row, index) => {
                const isItemSelected = isSelected(row.appManagementId);
                const labelId = `enhanced-table-checkbox-${index}`;
                const statusIcon = getStatusIcon(row.status);
                const _img =
                  row.uploadFile && row.uploadFile !== ""
                    ? `${env.resourceBase}/media/uploadimages/${row.uploadFile}`
                    : "/assets/no-image.png";

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.appManagementId}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        onChange={(event) =>
                          handleChange(event, row.appManagementId)
                        }
                      />
                    </TableCell>

                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <Link
                        to={`/dashboard/news-letter/${row.appManagementId}`}
                      >
                        {row.title}
                      </Link>
                    </TableCell>

                    <TableCell align="left">
                      <img width="50px" src={_img} alt="belt" />
                    </TableCell>
                    <TableCell align="left">{row.studioName}</TableCell>

                    <TableCell align="left">
                      <InfoIcon
                        className={classes.infoIcon}
                        onClick={() => {
                          handleOpenInfo(row);
                        }}
                      />
                    </TableCell>

                    <TableCell align="left">
                      {statusIcon && (
                        <Tooltip title={"Active"} arrow placement="top">
                          {statusIcon}
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          //count={data.length}
          count={
            getApiResult.appManages && getApiResult.appManages.length > 0
              ? getApiResult.appManages[0].totalCount
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
