import React from 'react';
import { useStore } from '../../store';
import FsLightbox from 'fslightbox-react';

export function CommonVideoPopup(props) {
  const { state } = useStore();

  const [toggler, setToggler] = React.useState(false);
  const [sources, setSources] = React.useState([]);
  const [popupIndex, setPopupIndex] = React.useState(0);

  const popup = state.popup;
  //console.log(popup, JSON.stringify((popup && popup.sources) || []), JSON.stringify(sources), (popup && popup.popupIndex), popupIndex)
  if (JSON.stringify((popup && popup.sources) || []) !== JSON.stringify(sources) ||
    ((popup && popup.popupIndex) || 0) !== popupIndex) {
    setSources(popup.sources);
    setPopupIndex(popup.popupIndex);
    setTimeout(() => { setToggler(!toggler) }, 10)
  } else {

  }
  //console.log(toggler, popupIndex, sources)
  return (
    <div className="">
      <FsLightbox

        toggler={toggler}
        sources={sources}
        key={popupIndex}
        onOpen={(e) => {
        }}
      />
    </div>

  );
}