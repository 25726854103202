import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import DashboardLayoutRoute from "layout/dashboardLayout";
import { Dashboard } from "site/members/dashboard/dashbaord";
import { Studios } from "site/members/studios/studios";
//import EnhancedTable from "site/members/studios/studios_test";
import { StudioSection } from "site/members/studio/studio";
import { StudioEdit } from "site/members/studios/studioEdit";
import { SetupAwards } from "site/members/setup/setupAwards";
import { SetupAward } from "site/members/setup/setupAward";

import { SetupPrograms } from "site/members/setup/setupPrograms";
import { SetupProgram } from "site/members/setup/setupProgram";

import { SetupBeltSizes } from "site/members/setup/setupBeltSizes";
import { SetupBeltSize } from "site/members/setup/setupBeltSize";
import { SetupUniforms } from "site/members/setup/setupUniforms";
import { SetupUniform } from "site/members/setup/setupUniform";
import { SetupGloves } from "site/members/setup/setupGloves";
import { SetupGlove } from "site/members/setup/setupGlove";
import { SetupTshirts } from "site/members/setup/setupTshirts";
import { SetupTshirt } from "site/members/setup/setupTshirt";
import { SetupMedicals } from "site/members/setup/setupMedicals";
import { SetupMedical } from "site/members/setup/setupMedical";
import { SetupHowHears } from "site/members/setup/setupHowHears";
import { SetupHowHear } from "site/members/setup/setupHowHear";
import { SetupHouseTeams } from "site/members/setup/setupHouseTeams";
import { SetupHouseTeam } from "site/members/setup/setupHouseTeam";
import { SetupBeltLevels } from "site/members/setup/setupBeltLevels";
import { SetupBeltLevel } from "site/members/setup/setupBeltLevel";

import { UserStudents } from "site/members/user/userStudents";
import { UserStudent } from "site/members/user/userStudent";
import { UserParent } from "site/members/user/userParent";
import { UserInstructors } from "site/members/user/userInstructors";
import { UserInstructor } from "site/members/user/userInstructor";

import { StudentDetail } from "site/members/student/studentDetail";
import { StudentEvaluations } from "site/members/student/studentEvaluations";
import { StudentEvaluation } from "site/members/student/studentEvaluation";
import { StudentRego } from "site/members/student/studentRego";
import { StudentAssignClasses } from "site/members/student/studentAssignClasses";
import { StudentAssignClass } from "site/members/student/studentAssignClass";

import { AppManTerms } from "site/members/appManage/appManTerms";
import { AppManTerm } from "site/members/appManage/appManTerm";
import { AppManNewsletters } from "site/members/appManage/appManNewsletters";
import { AppManNewsletter } from "site/members/appManage/appManNewsletter";

import { AppManStudentManuals } from "site/members/appManage/appManStudentManuals";
import { AppManStudentManual } from "site/members/appManage/appManStudentManual";

import { AppManStudentJournals } from "site/members/appManage/appManStudentJournals";
import { AppManStudentJournal } from "site/members/appManage/appManStudentJournal";

import { AppManBeltTyingVideos } from "site/members/appManage/appManBeltTyingVideos";
import { AppManBeltTyingVideo } from "site/members/appManage/appManBeltTyingVideo";

// import { Login } from 'auth';
// import { ChangePassword } from 'site/members/profile/changePassword';
// import { ForgotPassword } from 'auth/controls/forgotPassword/forgotPassword';
// import { ResetPassword } from 'auth/controls/resetPassword/resetPassword';
// import { VerifyEmail } from 'auth/controls/verifyEmail/verifyEmail';
// import { CreateAccountLoading } from 'auth/controls/createAccount/createAccount';
//import DashboardNoAuthLayoutRoute from 'layout/dashboardNoAuthLayout';
//import { MenuLoader } from './menuLoader';

const MemberLayout = ({ children }) => (
  <Switch>
    <DashboardLayoutRoute
      exact
      path="/dashboard/student/new"
      component={UserStudent}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/student/:studentId"
      component={UserStudent}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/student-detail/:studentId"
      component={StudentDetail}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/evaluation/:studentId/new"
      component={StudentEvaluation}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/evaluations/:studentId"
      component={StudentEvaluations}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/assign-class/:studentId/new"
      component={StudentAssignClass}
    />
    {/* <DashboardLayoutRoute
      exact
      path="/dashboard/assign-class/:studentId/:assignClassId"
      component={StudentAssignClass}
    /> */}
    <DashboardLayoutRoute
      exact
      path="/dashboard/assign-classes/:studentId"
      component={StudentAssignClasses}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/rego/:studentId"
      component={StudentRego}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/student/:studentId/parent/new"
      component={UserParent}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/students"
      component={UserStudents}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-award/new"
      component={SetupAward}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/terminology/new"
      component={AppManTerm}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/terminology/:appManagementId"
      component={AppManTerm}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/terminologies"
      component={AppManTerms}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/news-letter/new"
      component={AppManNewsletter}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/news-letter/:appManagementId"
      component={AppManNewsletter}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/news-letters"
      component={AppManNewsletters}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/student-manual/new"
      component={AppManStudentManual}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/student-manual/:appManagementId"
      component={AppManStudentManual}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/student-manuals"
      component={AppManStudentManuals}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/student-journal/new"
      component={AppManStudentJournal}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/student-journal/:appManagementId"
      component={AppManStudentJournal}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/student-journals"
      component={AppManStudentJournals}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/belt-tying-video/new"
      component={AppManBeltTyingVideo}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/belt-tying-video/:appManagementId"
      component={AppManBeltTyingVideo}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/belt-tying-videos"
      component={AppManBeltTyingVideos}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-award/new"
      component={SetupAward}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-award/new"
      component={SetupAward}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-award/:setupAwardId"
      component={SetupAward}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-awards"
      component={SetupAwards}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-belt-level/new"
      component={SetupBeltLevel}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-belt-level/:beltLevelId"
      component={SetupBeltLevel}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-belt-levels"
      component={SetupBeltLevels}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-belt-size/new"
      component={SetupBeltSize}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-belt-size/:beltSizeId"
      component={SetupBeltSize}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-belt-sizes"
      component={SetupBeltSizes}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-uniform/new"
      component={SetupUniform}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-uniform/:uniformId"
      component={SetupUniform}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-uniforms"
      component={SetupUniforms}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-glove/new"
      component={SetupGlove}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-glove/:gloveId"
      component={SetupGlove}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-gloves"
      component={SetupGloves}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-tshirt/new"
      component={SetupTshirt}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-tshirt/:tshirtId"
      component={SetupTshirt}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-tshirts"
      component={SetupTshirts}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-medical-condition/new"
      component={SetupMedical}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-medical-condition/:setupExInfoId"
      component={SetupMedical}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-medical-conditions"
      component={SetupMedicals}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-how-hear-about-us/new"
      component={SetupHowHear}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-how-hear-about-us/:setupExInfoId"
      component={SetupHowHear}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-how-hear-about-us-list"
      component={SetupHowHears}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-house-team/:houseTeamId"
      component={SetupHouseTeam}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-house-teams"
      component={SetupHouseTeams}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-program/new"
      component={SetupProgram}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-program/:programId"
      component={SetupProgram}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-programs"
      component={SetupPrograms}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/studio-edit/new"
      component={StudioEdit}
    />
    <DashboardLayoutRoute
      exact
      path="/dashboard/studio-edit/:studioId"
      component={StudioEdit}
    />
    <DashboardLayoutRoute
      path="/dashboard/studio/:studioId"
      component={StudioSection}
    />
    <DashboardLayoutRoute
      path="/dashboard/instructor/new"
      component={UserInstructor}
    />
    <DashboardLayoutRoute
      path="/dashboard/instructor/:userId"
      component={UserInstructor}
    />
    <DashboardLayoutRoute
      path="/dashboard/instructors"
      component={UserInstructors}
    />

    {/* <DashboardLayoutRoute
      exact
      path="/dashboard/studio/new"
      component={Studio}
    /> */}
    <DashboardLayoutRoute exact path="/dashboard/studios" component={Studios} />
    <DashboardLayoutRoute exact path="/dashboard" component={Dashboard} />
    <Route path="/">
      <Redirect to="/dashboard" status="301" />
    </Route>
  </Switch>
);

export default MemberLayout;
