import React from "react";
import env from "../../../env";
import { variables } from "../../../cssInJs";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import {
  Link,
  useHistory,
  //useLocation,
  useParams,
} from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
//import clsx from "clsx";
//import utils from "../../../utils";
import { ErrorMessage, Loading } from "components";
//import Autocomplete from "@material-ui/lab/Autocomplete";
//import { FilePond, registerPlugin } from "react-filepond";
//import "filepond/dist/filepond.min.css";
//import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
//import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
//import FilePondPluginImageResize from "filepond-plugin-image-resize";
//import FilePondPluginImageTransform from "filepond-plugin-image-transform";
//import DateRangeIcon from "@material-ui/icons/DateRange";
//import LensIcon from "@material-ui/icons/Lens";
//import AddIcon from "@material-ui/icons/Add";
//import DeleteIcon from "@material-ui/icons/Delete";
//import FilterListIcon from "@material-ui/icons/FilterList";
//import AttachFileIcon from "@material-ui/icons/AttachFile";
//import ArrowBackIcon from "@material-ui/icons/ArrowBack";
//import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
//import DescriptionIcon from "@material-ui/icons/Description";
//import queryString from "query-string";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  AppBar,
  Tab,
  Tabs,
  makeStyles,
  Typography,
  Paper,
  //Checkbox,
  Button,
  FormControl,
  TextField,
  //Backdrop,
  Box,
  FormControlLabel,
  FormHelperText,
  //FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

//registerPlugin(FilePondPluginFileValidateSize);
//registerPlugin(FilePondPluginFileValidateType);
//registerPlugin(FilePondPluginImageResize);
//registerPlugin(FilePondPluginImageTransform);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: "transparent",
  },
  marginDiv: {
    margin: theme.spacing(1),
    display: "inline-block",
    //width: "-webkit-fill-available",
  },
  formRoot: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor": {
      margin: theme.spacing(1),
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },
  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  filePondCon: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },
  formControl2: {
    margin: theme.spacing(1),
    width: "-webkit-fill-available",
    display: "inline-flex",
    "& label": {
      marginBottom: "0px",
      marginRight: "0px",
    },
  },
  formControl3: {
    margin: theme.spacing(1),
    width: "-webkit-fill-available",
    display: "inline-flex",
  },
  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
  },
  box: {
    backgroundColor: "white",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  title: {
    //marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  filePond_field: {
    marginTop: theme.spacing(1),
    zIndex: 2,
    height: "200px",
    "& .filepond--drop-label": {
      height: "200px",
    },
    "& .filepond--panel-root": {
      backgroundColor: "transparent !important",
    },
    "& ul.filepond--list.hide": {
      display: "none",
    },
  },
  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    zIndex: "1",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },
}));

const a11yProps = (index) => {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
};

const schema = yup.object().shape({
  parent1FirstName: yup.string().required("First Name is required."),
  parent1LastName: yup.string().required("Last Name is required."),
  parent1MobileNumber: yup.string().required("Mobile Number is required."),
  parent1HomePhoneNumber: yup
    .string()
    .required("Home Phone Number is required."),
  parent1Email: yup
    .string()
    .required("Email is required.")
    .email("Email is required"),
  parent1Relationship: yup.string().required("Relationship is required."),
  parent2FirstName: yup.string().nullable(true),
  parent2LastName: yup.string().nullable(true),
  parent2MobileNumber: yup.string().nullable(true),
  parent2HomePhoneNumber: yup.string().nullable(true),
  parent2Email: yup.string().nullable(true).email("Email is required"),
  parent2Relationship: yup.string().nullable(true),
  emergencyContactInfo: yup
    .string()
    .required("Emergency ContactInfo is required."),
  parent3FirstName: yup.string().required("First Name is required."),
  parent3LastName: yup.string().required("Last Name is required."),
  parent3MobileNumber: yup.string().nullable(true),
  parent3HomePhoneNumber: yup.string().nullable(true),
  parent3Email: yup.string().nullable(true).email("Email is required"),
  parent3Relationship: yup.string().nullable(true),
  accountHolderInfo: yup
    .string()
    .required("Account Holder Information is required."),
  parent4FirstName: yup.string().required("First Name is required."),
  parent4LastName: yup.string().required("Last Name is required."),
  parent4MobileNumber: yup.string().nullable(true),
  parent4HomePhoneNumber: yup.string().nullable(true),
  parent4Email: yup.string().nullable(true).email("Email is required"),
  parent4Relationship: yup.string().nullable(true),
});

//const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export function UserParent() {
  const { studentId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const previousController = React.useRef(null);
  const timerController = React.useRef();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const {
    register,
    handleSubmit,
    //setValue,
    reset,
    getValues,
    trigger,
    control,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      emergencyContactInfo: "other",
      accountHolderInfo: "other",
      //parent3FirstName : 'sfkjsdlkjfsdlkjsflksjdflksjd'
    },
  });

  const { emergencyContactInfo, accountHolderInfo } = getValues();

  const submit = async (dataModel) => {
    //console.log("check submit");
    setServerErrors([]);
    setBackdropOpen(true);
    //await sleep(3000);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/student/saveparent", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          studentId: studentId,
          ...dataModel,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }
      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
        setServerErrors(data.errors);
      } else {
        history.push(`/dashboard/students`);
      }
    } catch (error) {
      //console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
    
  }

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/student/getparent", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studentId: studentId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        // if (data.results && data.results.getStudentResult) {
        //   const _student = data.results.getStudentResult.student;

        //   const _studios = data.results.getStudentResult.studios;
        //   const _genders = data.results.getStudentResult.genders;
        //   const _stateList = data.results.getStudentResult.stateList;
        //   const _cityList = data.results.getStudentResult.cityList;
        //   const _aboutUsTypeList =
        //     data.results.getStudentResult.aboutUsTypeList;
        //   const _mediCondiTypeList =
        //     data.results.getStudentResult.mediCondiTypeList;

        //   if (_student) {
        //     reset({
        //       studioId: _student.studioId,
        //       profilePic: _student.profilePic,
        //       firstName: _student.firstName,
        //       lastName: _student.lastName,
        //       gender: _student.gender,
        //       dateOfBirth: new Date(_student.dateOfBirth),
        //       address1: _student.address1,
        //       address2: _student.address2,
        //       country: _student.country,
        //       state: _student.state,
        //       city: _student.city,
        //       postalCode: _student.postalCode,
        //       school: _student.school,
        //       principalName: _student.principalName,
        //       aboutUs: _student.aboutUs,
        //       medicalCondition: _student.medicalCondition,
        //       preMATraining: _student.preMATraining,
        //       benefitsAchieve: _student.benefitsAchieve,
        //       ambulanceCover: _student.ambulanceCover,
        //     });

        //     autoValue.studio = _studios.find(
        //       (x) => x.studioId === _student.studioId
        //     );
        //     autoValue.gender = _genders.find(
        //       (x) => x.value === _student.gender
        //     );
        //     autoValue.country = variables.countries.find(
        //       (x) => x.label === _student.country
        //     );
        //     autoValue.state = _stateList.find((x) => x.name === _student.state);
        //     autoValue.city = _cityList.find((x) => x.name === _student.city);
        //     autoValue.aboutUs = _aboutUsTypeList.find(
        //       (x) => x.value === _student.aboutUs
        //     );
        //     autoValue.medicalCondition = _mediCondiTypeList.find(
        //       (x) => x.value === _student.medicalCondition
        //     );

        //     setAutoValue({
        //       ...autoValue,
        //     });
        //   }

        //   setGetStudentResult({
        //     //editMode,
        //     //newBatch,
        //     ...data.results.getStudentResult,
        //   });
        // }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const {
        emergencyContactInfo,
        accountHolderInfo,
        parent1FirstName,
        parent1LastName,
        parent1MobileNumber,
        parent1HomePhoneNumber,
        parent1Email,
        parent1Relationship,
        parent2FirstName,
        parent2LastName,
        parent2MobileNumber,
        parent2HomePhoneNumber,
        parent2Email,
        parent2Relationship,
      } = value;
      if (name === "emergencyContactInfo") {
        if (emergencyContactInfo === "parent1") {
          reset({
            ...value,
            parent3FirstName: parent1FirstName,
            parent3LastName: parent1LastName,
            parent3MobileNumber: parent1MobileNumber,
            parent3HomePhoneNumber: parent1HomePhoneNumber,
            parent3Email: parent1Email,
            parent3Relationship: parent1Relationship,
          });
        } else if (emergencyContactInfo === "parent2") {
          reset({
            ...value,
            parent3FirstName: parent2FirstName,
            parent3LastName: parent2LastName,
            parent3MobileNumber: parent2MobileNumber,
            parent3HomePhoneNumber: parent2HomePhoneNumber,
            parent3Email: parent2Email,
            parent3Relationship: parent2Relationship,
          });
        } else {
          trigger(name);
        }
      } else if (name === "accountHolderInfo") {
        if (accountHolderInfo === "parent1") {
          reset({
            ...value,
            parent4FirstName: parent1FirstName,
            parent4LastName: parent1LastName,
            parent4MobileNumber: parent1MobileNumber,
            parent4HomePhoneNumber: parent1HomePhoneNumber,
            parent4Email: parent1Email,
            parent4Relationship: parent1Relationship,
          });
        } else if (accountHolderInfo === "parent2") {
          reset({
            ...value,
            parent4FirstName: parent2FirstName,
            parent4LastName: parent2LastName,
            parent4MobileNumber: parent2MobileNumber,
            parent4HomePhoneNumber: parent2HomePhoneNumber,
            parent4Email: parent2Email,
            parent4Relationship: parent2Relationship,
          });
        } else {
          trigger(name);
        }
      }
    });
    return () => subscription.unsubscribe();

    // eslint-disable-next-line
  }, [watch]);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <AppBar position="static">
          <Tabs value={"parent"} aria-label="wrapped label tabs example">
            <Tab
              value="student"
              label={"Student"}
              {...a11yProps("student")}
              component={Link}
              to={`/dashboard/property-metrics/setup-awards?awardtabtype=`}
            />
            <Tab
              value="parent"
              label="Parent"
              {...a11yProps("parent")}
              //component={Link}
              //to={`/dashboard/student/${studentId}/parent/new`}
              //onClick={checkParentTab}
            />
          </Tabs>
        </AppBar>

        <form
          method="POST"
          noValidate=""
          className={classes.formRoot}
          onSubmit={handleSubmit(submit)}
        >
          <Box className={classes.box}>
            <Typography className={classes.title}>
              {" "}
              Parent 1 Information{" "}
            </Typography>
            <TextField
              //id="outlined-basic"
              label="First Name*"
              variant="outlined"
              error={!!errors.parent1FirstName}
              helperText={errors?.parent1FirstName?.message}
              size="small"
              className={classes.textField}
              {...register("parent1FirstName")}
            />
            <TextField
              //id="outlined-basic"
              label="Last Name*"
              variant="outlined"
              error={!!errors.parent1LastName}
              helperText={errors?.parent1LastName?.message}
              size="small"
              className={classes.textField}
              {...register("parent1LastName")}
            />
            <TextField
              //id="outlined-basic"
              label="Mobile Number*"
              variant="outlined"
              error={!!errors.parent1MobileNumber}
              helperText={errors?.parent1MobileNumber?.message}
              size="small"
              className={classes.textField}
              {...register("parent1MobileNumber")}
            />
            <TextField
              //id="outlined-basic"
              label="Home Phone Number*"
              variant="outlined"
              error={!!errors.parent1HomePhoneNumber}
              helperText={errors?.parent1HomePhoneNumber?.message}
              size="small"
              className={classes.textField}
              {...register("parent1HomePhoneNumber")}
            />
            <TextField
              //id="outlined-basic"
              label="Email Id*"
              variant="outlined"
              error={!!errors.parent1Email}
              helperText={errors?.parent1Email?.message}
              size="small"
              className={classes.textField}
              {...register("parent1Email")}
            />
            <TextField
              //id="outlined-basic"
              label="Relationship to child*"
              variant="outlined"
              error={!!errors.parent1Relationship}
              helperText={errors?.parent1Relationship?.message}
              size="small"
              className={classes.textField}
              {...register("parent1Relationship")}
            />
          </Box>
          <Box className={classes.box}>
            <Typography className={classes.title}>
              {" "}
              Parent 2 Information{" "}
            </Typography>
            <TextField
              //id="outlined-basic"
              label="First Name"
              variant="outlined"
              error={!!errors.parent2FirstName}
              helperText={errors?.parent2FirstName?.message}
              size="small"
              className={classes.textField}
              {...register("parent2FirstName")}
            />
            <TextField
              //id="outlined-basic"
              label="Last Name"
              variant="outlined"
              error={!!errors.parent2LastName}
              helperText={errors?.parent2LastName?.message}
              size="small"
              className={classes.textField}
              {...register("parent2LastName")}
            />
            <TextField
              //id="outlined-basic"
              label="Mobile Number"
              variant="outlined"
              error={!!errors.parent2MobileNumber}
              helperText={errors?.parent2MobileNumber?.message}
              size="small"
              className={classes.textField}
              {...register("parent2MobileNumber")}
            />
            <TextField
              //id="outlined-basic"
              label="Home Phone Number"
              variant="outlined"
              error={!!errors.parent2HomePhoneNumber}
              helperText={errors?.parent2HomePhoneNumber?.message}
              size="small"
              className={classes.textField}
              {...register("parent2HomePhoneNumber")}
            />
            <TextField
              //id="outlined-basic"
              label="Email Id"
              variant="outlined"
              error={!!errors.parent2Email}
              helperText={errors?.parent2Email?.message}
              size="small"
              className={classes.textField}
              {...register("parent2Email")}
            />
            <TextField
              //id="outlined-basic"
              label="Relationship to child"
              variant="outlined"
              error={!!errors.parent2Relationship}
              helperText={errors?.parent2Relationship?.message}
              size="small"
              className={classes.textField}
              {...register("parent2Relationship")}
            />
          </Box>
          
          <Box className={classes.box}>
            <Typography className={classes.title}>
              {" "}
              Emergency Contact Information{" "}
            </Typography>
            <FormControl
              component="fieldset"
              className={classes.formControl3}
              size="small"
              error={!!errors.emergencyContactInfo}
            >
              <Controller
                control={control}
                name="emergencyContactInfo"
                aria-label="emergencyContactInfo"
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value="parent1"
                      control={<Radio color="primary" />}
                      label="Parent1"
                    />
                    <FormControlLabel
                      value="parent2"
                      control={<Radio color="primary" />}
                      label="Parent2"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio color="primary" />}
                      label="Other"
                    />
                  </RadioGroup>
                )}
              />

              {!!errors.emergencyContactInfo && (
                <FormHelperText error id="endDate-error">
                  {errors?.emergencyContactInfo?.message}
                </FormHelperText>
              )}
            </FormControl>

            <Controller
              control={control}
              name="parent3FirstName"
              aria-label="parent3FirstName"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="First Name*"
                  variant="outlined"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value || ""}
                  error={!!errors.parent3FirstName}
                  helperText={errors?.parent3FirstName?.message}
                  size="small"
                  disabled={emergencyContactInfo !== "other"}
                  className={classes.textField}
                />
              )}
            />

            <Controller
              control={control}
              name="parent3LastName"
              aria-label="parent3LastName"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Last Name*"
                  variant="outlined"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value || ""}
                  error={!!errors.parent3LastName}
                  helperText={errors?.parent3LastName?.message}
                  size="small"
                  disabled={emergencyContactInfo !== "other"}
                  className={classes.textField}
                />
              )}
            />

            <Controller
              control={control}
              name="parent3MobileNumber"
              aria-label="parent3MobileNumber"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Mobile Number"
                  variant="outlined"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value || ""}
                  error={!!errors.parent3MobileNumber}
                  helperText={errors?.parent3MobileNumber?.message}
                  size="small"
                  disabled={emergencyContactInfo !== "other"}
                  className={classes.textField}
                />
              )}
            />

            <Controller
              control={control}
              name="parent3HomePhoneNumber"
              aria-label="parent3HomePhoneNumber"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Home Phone Number"
                  variant="outlined"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value || ""}
                  error={!!errors.parent3HomePhoneNumber}
                  helperText={errors?.parent3HomePhoneNumber?.message}
                  size="small"
                  disabled={emergencyContactInfo !== "other"}
                  className={classes.textField}
                />
              )}
            />

            <Controller
              control={control}
              name="parent3Email"
              aria-label="parent3Email"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Email Id"
                  variant="outlined"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value || ""}
                  error={!!errors.parent3Email}
                  helperText={errors?.parent3Email?.message}
                  size="small"
                  disabled={emergencyContactInfo !== "other"}
                  className={classes.textField}
                />
              )}
            />

            <Controller
              control={control}
              name="parent3Relationship"
              aria-label="parent3Relationship"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Relation With Student"
                  variant="outlined"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value || ""}
                  error={!!errors.parent3Relationship}
                  helperText={errors?.parent3Relationship?.message}
                  size="small"
                  disabled={emergencyContactInfo !== "other"}
                  className={classes.textField}
                />
              )}
            />

          </Box>
          
          <Box className={classes.box}>
            <Typography className={classes.title}>
              {" "}
              Account Holder Information{" "}
            </Typography>
            <FormControl
              component="fieldset"
              className={classes.formControl3}
              size="small"
              error={!!errors.accountHolderInfo}
            >
              <Controller
                control={control}
                name="accountHolderInfo"
                aria-label="accountHolderInfo"
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value="parent1"
                      control={<Radio color="primary" />}
                      label="Parent1"
                    />
                    <FormControlLabel
                      value="parent2"
                      control={<Radio color="primary" />}
                      label="Parent2"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio color="primary" />}
                      label="Other"
                    />
                  </RadioGroup>
                )}
              />

              {!!errors.accountHolderInfo && (
                <FormHelperText error id="endDate-error">
                  {errors?.accountHolderInfo?.message}
                </FormHelperText>
              )}
            </FormControl>

            <Controller
              control={control}
              name="parent4FirstName"
              aria-label="parent4FirstName"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Account Holder Name*"
                  variant="outlined"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value || ""}
                  error={!!errors.parent4FirstName}
                  helperText={errors?.parent4FirstName?.message}
                  size="small"
                  disabled={accountHolderInfo !== "other"}
                  className={classes.textField}
                />
              )}
            />

            <Controller
              control={control}
              name="parent4LastName"
              aria-label="parent4LastName"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Account Holder Last Name*"
                  variant="outlined"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value || ""}
                  error={!!errors.parent4LastName}
                  helperText={errors?.parent4LastName?.message}
                  size="small"
                  disabled={accountHolderInfo !== "other"}
                  className={classes.textField}
                />
              )}
            />

            <Controller
              control={control}
              name="parent4MobileNumber"
              aria-label="parent4MobileNumber"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Account Holder Phone"
                  variant="outlined"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value || ""}
                  error={!!errors.parent4MobileNumber}
                  helperText={errors?.parent4MobileNumber?.message}
                  size="small"
                  disabled={accountHolderInfo !== "other"}
                  className={classes.textField}
                />
              )}
            />

            <Controller
              control={control}
              name="parent4HomePhoneNumber"
              aria-label="parent4HomePhoneNumber"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Account Holder Mobile"
                  variant="outlined"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value || ""}
                  error={!!errors.parent4HomePhoneNumber}
                  helperText={errors?.parent4HomePhoneNumber?.message}
                  size="small"
                  disabled={accountHolderInfo !== "other"}
                  className={classes.textField}
                />
              )}
            />

            <Controller
              control={control}
              name="parent4Email"
              aria-label="parent4Email"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Account Holder Email"
                  variant="outlined"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value || ""}
                  error={!!errors.parent4Email}
                  helperText={errors?.parent4Email?.message}
                  size="small"
                  disabled={accountHolderInfo !== "other"}
                  className={classes.textField}
                />
              )}
            />

          </Box>
          
          <div
            className={classes.buttonContainer}
            style={{ textAlign: "center" }}
          >
            <Button
              variant="contained"
              component={Link}
              size="small"
              to={`/dashboard/property-metrics/setup-awards`}
              disabled={isSubmitting}
              //color="primary"
              //className={classes.button}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: "1rem" }}
              //className={classes.button}
              startIcon={
                isSubmitting && (
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )
              }
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
}
