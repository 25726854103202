import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import env from "../../../env";
//import Autocomplete from "@material-ui/lab/Autocomplete";
import { variables } from "../../../cssInJs";
//import AccountCircle from "@material-ui/icons/AccountCircle";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import { useParams, Link } from "react-router-dom";
import { Loading, ErrorMessage } from "components";
import PropTypes from "prop-types";
import clsx from "clsx";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  ButtonGroup,
  Button,
  //Box,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  lighten,
  Toolbar,
  Typography,
  Tooltip,
  Backdrop,
} from "@material-ui/core";

const headCells = [
  {
    id: "ProgramName",
    numeric: false,
    disablePadding: true,
    label: "Program Name",
    hasSort: true,
  },
  {
    id: "ProgramCode",
    numeric: false,
    disablePadding: false,
    label: "Program Code",
    hasSort: true,
  },
  {
    id: "ProgramIcon",
    numeric: false,
    disablePadding: false,
    label: "Program Icon",
    hasSort: true,
  },
  {
    id: "AgeFrom",
    numeric: false,
    disablePadding: false,
    label: "Age From",
    hasSort: true,
  },
  {
    id: "AgeTo",
    numeric: false,
    disablePadding: false,
    label: "Age To",
    hasSort: true,
  },
  {
    id: "Seq",
    numeric: false,
    disablePadding: false,
    label: "Default Program",
    hasSort: true,
  },
  {
    id: "Weeks",
    numeric: false,
    disablePadding: false,
    label: "Weeks",
    hasSort: false,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    hasSort: false,
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "1px 1px 5px 0px #e0e0e0",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  root: {
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  root2: {
    width: "100%",
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
  button: {
    //margin: theme.spacing(1),
    width: "100%",
  },
  textField: {
    width: "-webkit-fill-available",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },
  // formControl: {
  //   //margin: theme.spacing(1),
  // },
}));

const schema = yup.object().shape({
  search: yup.string(),
});

// function countryToFlag(isoCode) {
//   return typeof String.fromCodePoint !== "undefined"
//     ? isoCode
//         .toUpperCase()
//         .replace(/./g, (char) =>
//           String.fromCodePoint(char.charCodeAt(0) + 127397)
//         )
//     : isoCode;
// }

export function Programs() {
  const [serverErrors, setServerErrors] = React.useState([]);
  const [data, setData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [selected, setSelected] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("ProgramName");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const timerController = React.useRef();
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [trigger, setTrigger] = React.useState(0);
  const previousController = React.useRef();

  // const [currentTabItem, setCurrentTabItem] = React.useState("dashboard");
  const classes = useStyles();

  const {
    register,
    getValues,
    //handleSubmit,
    //reset,
    //formState: { isSubmitting, errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { studioId } = useParams();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    if (data && data.length > 0) {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.programId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChange = (event, programId) => {
    //event.preventDefault();
    //event.stopPropagation();

    const selectedIndex = selected.indexOf(programId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, programId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }, 500);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/program/getprograms", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studioId: studioId,
            page: page,
            rowsPerPage: rowsPerPage,
            order: order,
            orderBy: orderBy,
            ...getValues(),
          }),
          ...options,
        });

        if (!res.ok) {
          //throw new Error(`Response status: ${res.status}`);
        }
        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.programs) {
          setData(data.results.programs);
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [trigger]);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item style={{ flexGrow: "1" }}>
          <form
            method="POST"
            noValidate=""
            //onSubmit={handleSubmit(submit)}
            className={classes.root}
          >
            <FormControl
              //className={classes.textField}
              variant="outlined"
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-password-cp">
                Search by Name
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-cp"
                type="text"
                inputProps={{
                  //...params.inputProps,
                  defaultValue: "",
                  autoComplete: "off",
                  //autoComplete: "new-password",
                }}
                {...register("search", {
                  onChange: (e) => {
                    searchType();
                  },
                })}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={150}
              />
            </FormControl>
          </form>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <ButtonGroup size="small" aria-label="small outlined button group">
            <Button
              component={Link}
              to={`/dashboard/studio/${studioId}/program/new`}
            >
              <AddIcon />
            </Button>
            {/* <Button>
              {" "}
              <SearchIcon />{" "}
            </Button>
            <Button>
              {" "}
              <SearchIcon />{" "}
            </Button> */}
          </ButtonGroup>
        </Grid>
      </Grid>

      {data && (
        <div className={classes.root2}>
          <Paper className={classes.paper}>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium" //small
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={data.length}
                />

                <TableBody>
                  {data.map((row, index) => {
                    const isItemSelected = isSelected(row.programId);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const _img =
                      row.programIcon && row.programIcon !== ""
                        ? `${env.resourceBase}/media/uploadimages/${row.programIcon}`
                        : "/assets/no-image.png";
                    return (
                      <TableRow
                        hover
                        //onClick={(event) => handleClick(event, row.studioId)}
                        //role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.programId}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onChange={(event) =>
                              handleChange(event, row.programId)
                            }
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <Link
                            to={`/dashboard/studio/${studioId}/program/${row.programId}`}
                          >
                            {row.programName}
                          </Link>
                        </TableCell>

                        <TableCell align="left">{row.programCode}</TableCell>

                        <TableCell align="left">
                          <img width="50px" src={_img} alt="belt" />
                        </TableCell>

                        <TableCell align="left">{row.ageFrom}</TableCell>
                        <TableCell align="left">{row.ageTo}</TableCell>
                        <TableCell align="left">
                          {row.programDefaultDisplay}
                        </TableCell>
                        <TableCell align="left"> {row.weeks} </TableCell>
                        <TableCell align="left">
                          {" "}
                          {row.programStatusDisplay}{" "}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              //count={data.length}
              count={data && data.length > 0 ? data[0].totalCount : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      )}
    </>
  );
}
