import React from "react";
import env from "../../../env";
import { variables } from "../../../cssInJs";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
  Link,
  useHistory,
  //useLocation,
  useParams,
} from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
//import clsx from "clsx";
//import utils from "../../../utils";
import { ErrorMessage, Loading } from "components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
//import DateRangeIcon from "@material-ui/icons/DateRange";
//import LensIcon from "@material-ui/icons/Lens";
//import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
//import FilterListIcon from "@material-ui/icons/FilterList";
//import AttachFileIcon from "@material-ui/icons/AttachFile";
//import ArrowBackIcon from "@material-ui/icons/ArrowBack";
//import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
//import DescriptionIcon from "@material-ui/icons/Description";
//import queryString from "query-string";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  AppBar,
  Tab,
  Tabs,
  makeStyles,
  Typography,
  Paper,
  Checkbox,
  Button,
  FormControl,
  TextField,
  Backdrop,
  Box,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";

registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginImageTransform);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: "transparent",
  },
  marginDiv: {
    margin: theme.spacing(1),
    display: "inline-block",
    //width: "-webkit-fill-available",
  },
  formRoot: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor": {
      margin: theme.spacing(1),
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },
  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  filePondCon: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },
  formControl2: {
    margin: theme.spacing(1),
    width: "-webkit-fill-available",
    display: "inline-flex",
    "& label": {
      marginBottom: "0px",
      marginRight: "0px",
    },
  },
  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
  },
  box: {
    backgroundColor: "white",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  title: {
    //marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  filePond_field: {
    marginTop: theme.spacing(1),
    zIndex: 2,
    height: "200px",
    "& .filepond--drop-label": {
      height: "200px",
    },
    "& .filepond--panel-root": {
      backgroundColor: "transparent !important",
    },
    "& ul.filepond--list.hide": {
      display: "none",
    },
  },
  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    zIndex: "1",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },
}));

const a11yProps = (index) => {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
};

const schema = yup.object().shape({
  studioId: yup.string().required("Studio is required."),
  checkSelectParent: yup.boolean(),
  parentId: yup.string().when("checkSelectParent", {
    is: true,
    then: (sch) => sch.required("Parent is required."),
  }),
  profilePic: yup.string().nullable(true),
  firstName: yup.string().required("Student First Name is required."),
  lastName: yup.string().required("Student Last Name is required."),
  gender: yup.number().required("Gender is required."),
  dateOfBirth: yup.date().required("Date Of Birth is required."),
  address1: yup.string().required("Address 1 is required."),
  address2: yup.string(),
  country: yup.string().required("Country is required."),
  state: yup.string().required("State is required."),
  city: yup.string().required("City is required."),
  postalCode: yup.string().required("Postal Code is required."),
  school: yup.string().required("School is required."),
  principalName: yup.string().nullable(true),
  aboutUs: yup.number().required("How did you hear about us is required."),
  medicalCondition: yup.number().required("Medical Condition is required."),
  preMATraining: yup.string().nullable(true),
  benefitsAchieve: yup.string().nullable(true),
  ambulanceCover: yup.boolean()
});

export function UserStudent() {
  const { studentId } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const previousController = React.useRef(null);
  const timerController = React.useRef();
  const filePondRef = React.useRef(null);
  const [getStudentResult, setGetStudentResult] = React.useState();
  const [backdropOpen, setBackdropOpen] = React.useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    //control,
    watch,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ambulanceCover: false },
  });

  const { checkSelectParent, dateOfBirth, profilePic, ambulanceCover } = getValues();

  const [autoValue, setAutoValue] = React.useState({
    studio: null,
    parent: null,
    gender: null,
    country: null,
    state: null,
    city: null,
    aboutUs: null,
    medicalCondition: null
  });

  const inputAutoChange = (event, id, id2, newValue, newValue2) => {
    setValue(id, newValue);
    trigger(id);
    setAutoValue({
      ...autoValue,
      [id2]: newValue2,
    });
  };

  const onChnageDateOfBirth = (date) => {
    setValue("dateOfBirth", date);
    trigger("dateOfBirth");
  };

  const checkParentTab = ($e) => {
    //$e.preventDefault();
    //$e.stopPropagation();
  }

  async function inputChangeCountry(event, newValue) {
    setBackdropOpen(true);
    if (previousController.current) {
      previousController.current.abort();
    }

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    const code = newValue.code;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/studio/getstatelist", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          code: code,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      }

      if (data.results && data.results.stateList) {
        setGetStudentResult({
          ...getStudentResult,
          stateList: data.results.stateList,
          cityList: [],
        });
      }

      timerController.current = setTimeout(() => {
        setBackdropOpen(false);
      }, 500);
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }

    setValue("country", newValue ? newValue.label : "");
    trigger("country");
    setValue("state", "");
    setValue("city", "");

    setAutoValue({
      ...autoValue,
      country: newValue,
      state: null,
      city: null,
    });
  }

  async function inputChangeState(event, newValue) {
    setBackdropOpen(true);
    
    if (previousController.current) {
      previousController.current.abort();
    }

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    const code = newValue.code;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/studio/getcitylist", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          code: code,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      }

      if (data.results && data.results.cityList) {
        setGetStudentResult({
          ...getStudentResult,
          cityList: data.results.cityList,
        });
      }

      timerController.current = setTimeout(() => {
        setBackdropOpen(false);
      }, 500);
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }

    setValue("state", newValue ? newValue.name : "");
    trigger("state");
    setValue("city", "");

    setAutoValue({
      ...autoValue,
      state: newValue,
      city: null,
    });
  }

  async function submit(dataModel) {

    setServerErrors([]);
    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/student/savestudent", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          studentId: studentId,
          ...dataModel,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }
      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
        setServerErrors(data.errors);
      } else {
        history.push(`/dashboard/students`);
      }
    } catch (error) {
      //console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  }

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/student/getstudent", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            //setupAwardId: setupAwardId,
            studentId: studentId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getStudentResult) {

          const _student = data.results.getStudentResult.student;
          
          const _studios = data.results.getStudentResult.studios;
          const _genders = data.results.getStudentResult.genders;
          const _stateList = data.results.getStudentResult.stateList;
          const _cityList = data.results.getStudentResult.cityList;
          const _aboutUsTypeList = data.results.getStudentResult.aboutUsTypeList;
          const _mediCondiTypeList = data.results.getStudentResult.mediCondiTypeList;

          if (_student) {
            reset({
              studioId: _student.studioId,
              profilePic: _student.profilePic,
              firstName: _student.firstName,
              lastName: _student.lastName,
              gender: _student.gender,
              dateOfBirth: new Date(_student.dateOfBirth),
              address1: _student.address1,
              address2: _student.address2,
              country: _student.country,
              state: _student.state,
              city: _student.city,
              postalCode: _student.postalCode,
              school: _student.school,
              principalName: _student.principalName,
              aboutUs: _student.aboutUs,
              medicalCondition: _student.medicalCondition,
              preMATraining: _student.preMATraining,
              benefitsAchieve: _student.benefitsAchieve,
              ambulanceCover: _student.ambulanceCover
            });

            autoValue.studio = _studios.find(x => x.studioId === _student.studioId);
            autoValue.gender = _genders.find(x => x.value === _student.gender);
            autoValue.country = variables.countries.find(x => x.label === _student.country);
            autoValue.state = _stateList.find(x => x.name === _student.state);
            autoValue.city = _cityList.find(x => x.name === _student.city);
            autoValue.aboutUs = _aboutUsTypeList.find(x => x.value === _student.aboutUs);
            autoValue.medicalCondition = _mediCondiTypeList.find(x => x.setupExInfoId === _student.medicalCondition);

            setAutoValue({
              ...autoValue
            });
            
          }

          setGetStudentResult({
            //editMode,
            //newBatch,
            ...data.results.getStudentResult,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "checkSelectParent" || name === "ambulanceCover") {
        trigger(name);
      }
    });
    return () => subscription.unsubscribe();

    // eslint-disable-next-line
  }, [watch]);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <img src="/assets/loader.36c5450235c205d74679.gif" alt="loader" />
      </Backdrop>
      <Paper className={classes.paper} elevation={0}>
        <AppBar position="static">
          <Tabs value={"student"} aria-label="wrapped label tabs example">
            <Tab
              value="student"
              label={"Student"}
              {...a11yProps("student")}
              component={Link}
              to={`/dashboard/property-metrics/setup-awards?awardtabtype=`}
            />

            <Tab
              value="parent"
              label="Parent"
              {...a11yProps("parent")}
              component={Link}
              to={`/dashboard/student/${studentId}/parent/new`}
              onClick={checkParentTab}
            />
          </Tabs>
        </AppBar>

        <form
          method="POST"
          noValidate=""
          className={classes.formRoot}
          onSubmit={handleSubmit(submit)}
        >
          <Box className={classes.box}>
            
            <Autocomplete
              id="studioId"
              //disabled={!checkCreateBatch}
              options={getStudentResult.studios}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.studioName}
              getOptionSelected={(option, value) =>
                option.studioName === value.studioName
              }
              value={autoValue.studio || null}
              onChange={(event, newValue) => {
                inputAutoChange(
                  event,
                  "studioId",
                  "studio",
                  newValue ? newValue.studioId : null,
                  newValue
                );
              }}
              renderOption={(option) => (
                <React.Fragment>{option.studioName}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Studio*"
                  variant="outlined"
                  size="small"
                  error={!!errors.studioId}
                  helperText={errors?.studioId?.message}
                  inputProps={{
                    ...params.inputProps,
                    //autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <FormControl
              error={!!errors.checkSelectParent}
              component="fieldset"
              className={classes.formControl2}
              size="small"
            >
              <FormControlLabel
                control={
                  <Checkbox size="small" {...register("checkSelectParent")} />
                }
                className={`${
                  !!errors.checkSelectParent ? "Mui-error" : "Mui-error-non"
                }`}
                label="Select Parent"
              />
            </FormControl>

            {checkSelectParent && (
              <Autocomplete
                id="parentId"
                //disabled={!checkCreateBatch}
                options={getStudentResult.studios}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.studioName}
                getOptionSelected={(option, value) =>
                  option.studioName === value.studioName
                }
                value={autoValue.studio || null}
                onChange={(event, newValue) => {
                  inputAutoChange(
                    event,
                    "parentId",
                    "parent",
                    newValue ? newValue.studioId : null,
                    newValue
                  );
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.studioName}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Parent*"
                    variant="outlined"
                    size="small"
                    error={!!errors.parentId}
                    helperText={errors?.parentId?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </Box>

          <Box className={classes.box}>
            <Typography className={classes.title}>Basic Information</Typography>
            <TextField
              //id="outlined-basic"
              label="Student First Name*"
              variant="outlined"
              error={!!errors.firstName}
              helperText={errors?.firstName?.message}
              size="small"
              className={classes.textField}
              {...register("firstName")}
            />

            <TextField
              //id="outlined-basic"
              label="Student Last Name*"
              variant="outlined"
              error={!!errors.lastName}
              helperText={errors?.lastName?.message}
              size="small"
              className={classes.textField}
              {...register("lastName")}
            />

            <Autocomplete
              id="gender"
              //disabled={!checkCreateBatch}
              options={getStudentResult.genders}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              value={autoValue.gender || null}
              onChange={(event, newValue) => {
                inputAutoChange(
                  event,
                  "gender",
                  "gender",
                  newValue ? newValue.value : null,
                  newValue
                );
              }}
              renderOption={(option) => (
                <React.Fragment>{option.name}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Gender*"
                  variant="outlined"
                  size="small"
                  error={!!errors.gender}
                  helperText={errors?.gender?.message}
                  inputProps={{
                    ...params.inputProps,
                    //autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <div className={classes.datePicker}>
              <Typography variant="caption">
                {" "}
                Certificate Grading Date{" "}
              </Typography>
              <DatePicker
                selected={dateOfBirth}
                onChange={onChnageDateOfBirth}
                showIcon
                isClearable
                dateFormat="dd/MM/yyyy"
                placeholderText={"dd/mm/yyyy"}
                toggleCalendarOnIconClick
              />
              {!!errors.dateOfBirth && (
                <FormHelperText error id="startDate-error">
                  {errors?.dateOfBirth?.message}
                </FormHelperText>
              )}
            </div>

            <div
              className={classes.filePondCon}
              style={{ position: "relative", backgroundColor: "#f1f0ef" }}
            >
              <FilePond
                className={classes.filePond_field}
                allowMultiple={false}
                maxFiles={1}
                name="signatureImage"
                maxFileSize={"10MB"}
                allowDrop={false}
                allowReorder={true}
                //labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                labelIdle=""
                acceptedFileTypes={["image/*"]}
                ref={filePondRef}
                server={{
                  process: {
                    url: env.apiBase + "/api/file/uploadimage",
                    onload: (res) => {
                      setValue("profilePic", res);
                      trigger("profilePic");
                      setTimeout(() => {
                        const f_list =
                          document.querySelector("ul.filepond--list");
                        if (f_list) {
                          f_list.classList.add("hide");
                        }
                      }, 1500);
                    },
                  },
                  fetch: null,
                  revert: null,
                }}
                onremovefile={() => {
                  //setValue(`criteria.${index}.attachment`, "");
                  //trigger(`criteria.${index}.attachment`);
                }}
                oninitfile={() => {
                  const f_list = document.querySelector("ul.filepond--list");
                  if (f_list) {
                    f_list.classList.remove("hide");
                  }
                }}
              />
              <div
                style={{
                  position: "absolute",
                  //maxWidth: "100px",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                }}
              >
                <img
                  src={
                    profilePic && profilePic !== ""
                      ? `${env.resourceBase}/media/uploadimages/${profilePic}`
                      : "/assets/no-image.png"
                  }
                  width="100%"
                  style={{ maxWidth: "100px" }}
                />
                <Typography variant="caption" style={{ display: "block" }}>
                  Drop file here or click to upload
                </Typography>
              </div>
              <div
                style={{
                  position: "absolute",
                  //maxWidth: "50px",
                  top: "0px",
                  right: "0px",
                  zIndex: "3",
                  cursor: "pointer",
                  //left: "50%",
                  //transform: "translate(-50%, -50%)",
                }}
                onClick={() => {
                  filePondRef.current.removeFiles();
                  setValue("profilePic", "");
                  trigger("profilePic");
                }}
              >
                <DeleteIcon />
              </div>
            </div>
          </Box>

          <Box className={classes.box}>
            <Typography className={classes.title}>
              Address Information
            </Typography>
            <TextField
              //id="outlined-basic"
              label="Address 1*"
              variant="outlined"
              error={!!errors.address1}
              helperText={errors?.address1?.message}
              size="small"
              className={classes.textField}
              {...register("address1")}
            />
            <TextField
              //id="outlined-basic"
              label="Address 2*"
              variant="outlined"
              error={!!errors.address2}
              helperText={errors?.address2?.message}
              size="small"
              className={classes.textField}
              {...register("address2")}
            />

            <Autocomplete
              id="country"
              //disabled={!checkCreateBatch}
              options={variables.countries}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) =>
                option.label === value.label
              }
              value={autoValue.country || null}
              onChange={(event, newValue) => {
                inputChangeCountry(event, newValue);
              }}
              renderOption={(option) => (
                <React.Fragment>{option.label}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country*"
                  variant="outlined"
                  size="small"
                  error={!!errors.country}
                  helperText={errors?.country?.message}
                  inputProps={{
                    ...params.inputProps,
                    //autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <Autocomplete
              id="state"
              //disabled={!checkCreateBatch}
              options={getStudentResult.stateList}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              value={autoValue.state || null}
              onChange={(event, newValue) => {
                inputChangeState(event, newValue);
              }}
              renderOption={(option) => (
                <React.Fragment>{option.name}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State*"
                  variant="outlined"
                  size="small"
                  error={!!errors.state}
                  helperText={errors?.state?.message}
                  inputProps={{
                    ...params.inputProps,
                    //autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <Autocomplete
              id="city"
              //disabled={!checkCreateBatch}
              options={getStudentResult.cityList}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              value={autoValue.city || null}
              onChange={(event, newValue) => {
                inputAutoChange(
                  event,
                  "city",
                  "city",
                  newValue ? newValue.name : null,
                  newValue
                );
              }}
              renderOption={(option) => (
                <React.Fragment>{option.name}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City*"
                  variant="outlined"
                  size="small"
                  error={!!errors.city}
                  helperText={errors?.city?.message}
                  inputProps={{
                    ...params.inputProps,
                    //autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <TextField
              //id="outlined-basic"
              label="Postal Code*"
              variant="outlined"
              error={!!errors.postalCode}
              helperText={errors?.postalCode?.message}
              size="small"
              className={classes.textField}
              {...register("postalCode")}
            />
          </Box>

          <Box className={classes.box}>
            <Typography className={classes.title}>
              School Information
            </Typography>

            <TextField
              //id="outlined-basic"
              label="School*"
              variant="outlined"
              error={!!errors.school}
              helperText={errors?.school?.message}
              size="small"
              className={classes.textField}
              {...register("school")}
            />
            <TextField
              //id="outlined-basic"
              label="Principal Name*"
              variant="outlined"
              error={!!errors.principalName}
              helperText={errors?.principalName?.message}
              size="small"
              className={classes.textField}
              {...register("principalName")}
            />
          </Box>

          <Box className={classes.box}>
            
            <Typography className={classes.title}>
              Additional Information
            </Typography>

            <Autocomplete
              id="aboutUs"
              //disabled={!checkCreateBatch}
              options={getStudentResult.aboutUsTypeList}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              value={autoValue.aboutUs || null}
              onChange={(event, newValue) => {
                inputAutoChange(
                  event,
                  "aboutUs",
                  "aboutUs",
                  newValue ? newValue.value : null,
                  newValue
                );
              }}
              renderOption={(option) => (
                <React.Fragment>{option.name}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="How did you hear about us?*"
                  variant="outlined"
                  size="small"
                  error={!!errors.aboutUs}
                  helperText={errors?.aboutUs?.message}
                  inputProps={{
                    ...params.inputProps,
                    //autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <Autocomplete
              id="medicalCondition"
              //disabled={!checkCreateBatch}
              options={getStudentResult.mediCondiTypeList}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              value={autoValue.medicalCondition || null}
              onChange={(event, newValue) => {
                inputAutoChange(
                  event,
                  "medicalCondition",
                  "medicalCondition",
                  newValue ? newValue.setupExInfoId : null,
                  newValue
                );
              }}
              renderOption={(option) => (
                <React.Fragment>{option.name}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Medical Condition*"
                  variant="outlined"
                  size="small"
                  error={!!errors.medicalCondition}
                  helperText={errors?.medicalCondition?.message}
                  inputProps={{
                    ...params.inputProps,
                    //autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <TextField
              //id="outlined-basic"
              label="Previous Martial Arts Training"
              variant="outlined"
              error={!!errors.preMATraining}
              helperText={errors?.preMATraining?.message}
              size="small"
              className={classes.textField}
              multiline
              minRows={3}
              {...register("preMATraining")}
            />

            <TextField
              //id="outlined-basic"
              label="Benefits Hoping To Achieve"
              variant="outlined"
              error={!!errors.benefitsAchieve}
              helperText={errors?.benefitsAchieve?.message}
              size="small"
              className={classes.textField}
              multiline
              minRows={3}
              {...register("benefitsAchieve")}
            />
          </Box>

          <Box className={classes.box}>

            <Typography className={classes.title}>Ambulance Cover</Typography>
            
            <FormControl
              error={!!errors.ambulanceCover}
              component="fieldset"
              className={classes.formControl2}
              size="small"
            >
              <FormControlLabel
                control={
                  <Checkbox size="small" {...register("ambulanceCover")} checked = {ambulanceCover} />
                }
                className={`${
                  !!errors.ambulanceCover ? "Mui-error" : "Mui-error-non"
                }`}
                label="Yes"
              />
            </FormControl>
            
          </Box>

          <div
            className={classes.buttonContainer}
            style={{ textAlign: "center" }}
          >
            <Button
              variant="contained"
              component={Link}
              size="small"
              to={`/dashboard/property-metrics/setup-awards`}
              disabled={isSubmitting}
              //color="primary"
              //className={classes.button}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: "1rem" }}
              //className={classes.button}
              startIcon={
                isSubmitting && (
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )
              }
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
}
