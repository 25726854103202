import utils from "../utils";
import env from "../env";
import { isMin } from "../lib/css-emotion";

export const isMobile = () => {
  // isMin.md() ==> min-width: 768px
  return !isMin.md();
};

export function resourcePath(url) {
  if (!url) return null;
  if (url && url.indexOf("/assets/") === 0) return url;
  return utils.url.isExternalLink(url) ? url : env.resourceBase + url;
}

export function fullUrl(url) {
  return utils.url.isExternalLink(url) ? url : env.baseOrigin + url;
}

export function formatDDMMYYYY(dateObj) {
  const date = dateObj;
  //console.log(dateObj, date)
  if (!date) return "";
  var dd = date.getDate();
  var mm = date.getMonth() + 1; //January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return `${dd}/${mm}/${yyyy}`;
}

export function formatDDMMYYYYWithTime(dateObj) {
  const date = dateObj;
  //console.log(dateObj, date)
  if (!date) return "";
  var dd = date.getDate();
  var mm = date.getMonth() + 1; //January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return `${dd}/${mm}/${yyyy} ${date.getHours()}:${date.getMinutes()}`;
}

export function calculateAge(dateOfBirth) {
  var birthYear = dateOfBirth.getFullYear();
  var birthMonth = dateOfBirth.getMonth();
  var birthDay = dateOfBirth.getDate();

  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();
  var currentMonth = currentDate.getMonth();
  var currentDay = currentDate.getDate();

  var age = currentYear - birthYear;
  if (currentMonth < birthMonth) {
    age--;
  } else if (currentMonth === birthMonth && currentDay < birthDay) {
    age--;
  }
  return age;
}

export function getRandomText() {
  let result = "";
  const length = 8;
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const isNZ = env.locale === "nz";
