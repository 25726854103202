import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  //Typography,
  //Box,
} from "@material-ui/core";
import { variables } from "../../../cssInJs";
//import { Programs } from "./programs";
import {
  //useParams,
  Link,
} from "react-router-dom";

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop : '1px'
  },
  tab: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "72px",
    },
  },
  appBar: {
    backgroundColor: variables.colorLightGray,
    color: variables.fontColor,
  },
}));

function createTab(id, label, link = null) {
  return { id, label, link };
}

function getValue(pathname) {
  let result = "dashboard";
  if (
    pathname.includes("/program/new") ||
    pathname.includes("/programs") ||
    pathname.includes("/program/")
  ) {
    result = "programs";
  }
  return result;
}

export function ConfigMenuStudio({ studioId, pathname }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(() => {
    return getValue(pathname);
  });
  //const { id, programId } = useParams();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabItems = [
    createTab("dashboard", "Dashboard"),
    createTab("programs", "Programs"),
    createTab("houseTeams", "House Teams"),
    createTab("beltLevels", "Belt Levels"),
    createTab("awards", "Awards", "awards?awardtabtype=1"),
    createTab("settings", "Settings", "studio-setting"),
    createTab("terms", "Terms"),
    createTab("batches", "Classes"),
  ];

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
        >
          {tabItems.map((row, index) => {
            const link = row.link ? row.link : row.id;
            return (
              <Tab
                key={row.id}
                value={row.id}
                label={row.label}
                wrapped
                component={Link}
                to={`/dashboard/studio/${studioId}/${link}`}
                className={classes.tab}
                {...a11yProps(row.id)}
              />
            );
          })}
        </Tabs>
      </AppBar>
    </div>
  );
}