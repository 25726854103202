import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useParams, Link, useHistory } from "react-router-dom";
import { variables } from "../../../cssInJs";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import env from "../../../env";

import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { ErrorMessage, Loading } from "components";

import {
  //IconButton,
  //InputAdornment,
  //OutlinedInput,
  //InputLabel,
  //FormControl,
  //ButtonGroup,
  Button,
  Typography,
  FormHelperText,
  //Container,
  //Button,
  TextField,
  Grid,
  //Checkbox,
  //FormControlLabel,
  makeStyles,
  Box,
  Backdrop,
  //FormControl,
  //InputLabel,
  //Select,
} from "@material-ui/core";

registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginImageTransform);

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: "white",
    //paddingTop: theme.spacing(1),
    //paddingBottom: theme.spacing(1),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),

      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      //marginLeft: theme.spacing(1),
      width: "-webkit-fill-available",
      //width: "50%",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },

    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },
  button: {
    //margin: theme.spacing(1),
    width: "100%",
  },
  textField: {
    //width: "-webkit-fill-available",
    //paddingLeft: theme.spacing(1),
    //paddingRight: theme.spacing(1),
    //marginTop: theme.spacing(1),
    //marginBottom: theme.spacing(1),

    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    //margin: theme.spacing(1),
    //minWidth: 120,
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    //marginTop: theme.spacing(1),
    //marginBottom: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  boxFormControl: {
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },

  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    //marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  box: {
    backgroundColor: "white",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },

  gridContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    //marginTop : '-10px'
  },
  option: {
    fontSize: 14,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },
}));

const schema = yup.object().shape({
  studioName: yup.string().required("Studio Name is required."),
  studioLogo: yup.string(),
  address1: yup.string().required("Address 1 is required."),
  address2: yup.string(),
  country: yup.string().required("Country is required."),
  state: yup.string().required("State is required."),
  city: yup.string().required("City is required."),
  postalCode: yup.string().required("Postal Code is required."),
  phone: yup.string().required("Phone is required."),
  email: yup.string().required("Email is required."),
  websiteUrl: yup.string(),
  facebookLink: yup.string(),
  instaLink: yup.string(),
  headerLogo: yup.string(),
  footerLogo: yup.string(),
});

export function StudioEdit() {
  const { studioId } = useParams();
  const classes = useStyles();
  const previousController = React.useRef();
  const timerController = React.useRef();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [countryAuto, setCountryAuto] = React.useState();
  const [stateList, setStateList] = React.useState([]);
  const [stateAuto, setStateAuto] = React.useState();
  const [cityList, setCityList] = React.useState([]);
  const [cityAuto, setCityAuto] = React.useState();

  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [files, setFiles] = React.useState({
    studioLogo: "",
    headerLogo: "",
    footerLogo: "",
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: { country: ""},
  });

  const history = useHistory();

  async function submit(dataModel) {
    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/studio/savestudio", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          studioId: studioId,
          ...dataModel,
        }),
        ...options,
      });

      if (!res.ok) {
        //throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();
      
      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
        setServerErrors(data.errors);
      } else {
        history.push(`/dashboard/studios`);
      }
    } catch (error) {
      console.error(error.message);
      setBackdropOpen(false);
    }
  }

  async function inputChangeCountry(event, newValue) {

    setStateList([]);
    setCityList([]);

    setValue("country", newValue ? newValue.label : "");
    setCountryAuto(newValue);

    setValue("state", "");
    setValue("city", "");

    setStateAuto(null);
    setCityAuto(null);

    if (!newValue) {
      return;
    }

    setBackdropOpen(true);
    const code = newValue.code;

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/studio/getstatelist", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          code: code,
        }),
        ...options,
      });

      if (!res.ok) {
        //throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      }

      if (data.results && data.results.stateList) {
        setStateList(data.results.stateList);
      }

      if (timerController.current) {
        clearTimeout(timerController.current);
      }

      timerController.current = setTimeout(() => {
        setBackdropOpen(false);
      }, 500);
      //setBackdropOpen(false);
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
    }
  }

  async function inputChangeState(event, newValue) {
    setValue("state", newValue ? newValue.name : "");
    setStateAuto(newValue);

    setCityList([]);
    setValue("city", "");
    setCityAuto(null);

    if (!newValue) {
      return;
    }

    setBackdropOpen(true);
    const code = newValue.code;

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/studio/getcitylist", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          code: code,
        }),
        ...options,
      });

      if (!res.ok) {
        //throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      }

      if (data.results && data.results.cityList) {
        setCityList(data.results.cityList);
      }

      if (timerController.current) {
        clearTimeout(timerController.current);
      }

      timerController.current = setTimeout(() => {
        setBackdropOpen(false);
      }, 500);
      //setBackdropOpen(false);
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
    }
  }

  function inputChangeCity(event, newValue) {
    setValue("city", newValue ? newValue.name : "");
    setCityAuto(newValue);
  }

  useEffect(() => {
    if (!studioId) {
      setLoading(false);
      return;
    }

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/studio/getstudio", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studioId: studioId,
          }),
          ...options,
        });

        if (!res.ok) {
          //throw new Error(`Response status: ${res.status}`);
        }
        const data = await res.json();
        
        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.studio) {
          const studio = data.results.studio;
          setValue("studioName", studio.studioName);
          setValue("studioLogo", studio.studioLogo);
          setValue("address1", studio.address1);
          setValue("address2", studio.address2);
          setValue("country", studio.country);
          setValue("state", studio.state);
          setValue("city", studio.city);
          setValue("postalCode", studio.postalCode);
          setValue("phone", studio.phone);
          setValue("email", studio.email);
          setValue("websiteUrl", studio.websiteUrl);
          setValue("facebookLink", studio.facebookLink);
          setValue("instaLink", studio.instaLink);
          setValue("headerLogo", studio.headerLogo);
          setValue("footerLogo", studio.footerLogo);

          const countryCode = variables.countries.find(
            (x) => x.label === studio.country
          );
          setCountryAuto(countryCode);
          setStateAuto({ name: studio.state, iso2: studio.state });
          setCityAuto({ name: studio.city });

          setFiles({
            studioLogo: studio.studioLogo,
            headerLogo: studio.headerLogo,
            footerLogo: studio.footerLogo,
          });
        }

        if (data.results && data.results.stateList) {
          setStateList(data.results.stateList);
        }

        if (data.results && data.results.cityList) {
          setCityList(data.results.cityList);
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        {/* <CircularProgress color="inherit" /> */}
        <img src="/assets/loader.36c5450235c205d74679.gif" alt="loader" />
      </Backdrop>

      <Typography>
        <DateRangeIcon /> Studio
      </Typography>

      <form
        method="POST"
        noValidate=""
        className={classes.root}
        onSubmit={handleSubmit(submit)}
      >
        <Box className={classes.box}>
          <Typography className={classes.title}>
            Studio General Information{" "}
          </Typography>
          <TextField
            //id="outlined-basic"
            label="Studio Name*"
            variant="outlined"
            error={!!errors.studioName}
            helperText={errors?.studioName?.message}
            size="small"
            className={classes.textField}
            {...register("studioName")}
          />

          <Grid
            container
            spacing={3}
            alignItems="center"
            className={classes.gridContainer}
          >
            <Grid item xs={3}>
              <Typography variant="caption" gutterBottom>
                Studio Logo
              </Typography>
              <FilePond
                allowMultiple={false}
                maxFiles={1}
                name="studioLogo"
                maxFileSize={"10MB"}
                allowDrop={false}
                allowReorder={true}
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                acceptedFileTypes={["image/*"]}
                server={{
                  process: {
                    url: env.apiBase + "/api/file/uploadimage",
                    onload: (res) => {
                      setValue("studioLogo", res);
                      const _studioLogo = getValues("studioLogo");
                      setFiles({ ...files, studioLogo: _studioLogo });
                    },
                  },
                  fetch: null,
                  revert: null,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <div style={{ maxWidth: "100px" }}>
                <img
                  src={`${
                    files.studioLogo && files.studioLogo !== ""
                      ? files.studioLogo
                      : "/assets/no-image.png"
                  }`}
                  width="100%"
                  alt="logo"
                />
              </div>
            </Grid>

            {!!errors.studioLogo && (
              <Grid item xs={12}>
                <FormHelperText error id="studioLogo-error">
                  {errors?.studioLogo?.message}
                </FormHelperText>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box className={classes.box}>
          <Typography className={classes.title}>
            {" "}
            Studio Contact Information{" "}
          </Typography>

          <TextField
            //id="outlined-basic"
            label="Address 1*"
            variant="outlined"
            error={!!errors.address1}
            helperText={errors?.address1?.message}
            size="small"
            className={classes.textField}
            {...register("address1")}
          />

          <TextField
            //id="outlined-basic"
            label="Address 2"
            variant="outlined"
            error={!!errors.address2}
            helperText={errors?.address2?.message}
            size="small"
            className={classes.textField}
            {...register("address2")}
          />

          <Autocomplete
            id="country"
            options={variables.countries}
            className={classes.formControl}
            classes={{
              option: classes.option,
            }}
            // inputValue={countryAuto}
            // onInputChange={(event, newInputValue) => {
            //   setCountryAuto(newInputValue);
            // }}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(option) => (
              <React.Fragment>{option.label}</React.Fragment>
            )}
            value={countryAuto || null}
            onChange={(event, newValue) => {
              inputChangeCountry(event, newValue);
            }}
            getOptionSelected={(option, value) => option.label === value.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country*"
                variant="outlined"
                size="small"
                error={!!errors.country}
                helperText={errors?.country?.message}
                inputProps={{
                  ...params.inputProps,
                  //autoComplete: "new-password", // disable autocomplete and autofill
                }}
                //{...register("country")}
              />
            )}
          />

          <Autocomplete
            id="state"
            options={stateList}
            className={classes.formControl}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            value={stateAuto || null}
            onChange={(event, newValue) => {
              inputChangeState(event, newValue);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => (
              <React.Fragment>{option.name}</React.Fragment>
            )}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="State*"
                variant="outlined"
                size="small"
                error={!!errors.state}
                helperText={errors?.state?.message}
                inputProps={{
                  ...params.inputProps,
                  //autoComplete: "new-password", // disable autocomplete and autofill
                }}
                //{...register("state")}
              />
            )}
          />

          <Autocomplete
            id="city"
            options={cityList}
            className={classes.formControl}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.name}
            value={cityAuto || null}
            onChange={(event, newValue) => {
              inputChangeCity(event, newValue);
            }}
            renderOption={(option) => (
              <React.Fragment>{option.name}</React.Fragment>
            )}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="City*"
                variant="outlined"
                size="small"
                error={!!errors.city}
                helperText={errors?.city?.message}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                //{...register("city")}
              />
            )}
          />

          <TextField
            //id="outlined-basic"
            label="Postal Code*"
            variant="outlined"
            error={!!errors.postalCode}
            helperText={errors?.postalCode?.message}
            size="small"
            className={classes.textField}
            {...register("postalCode")}
          />
          <TextField
            //id="outlined-basic"
            label="Phone*"
            variant="outlined"
            error={!!errors.phone}
            helperText={errors?.phone?.message}
            size="small"
            className={classes.textField}
            {...register("phone")}
          />
          <TextField
            //id="outlined-basic"
            label="Email*"
            variant="outlined"
            error={!!errors.email}
            helperText={errors?.email?.message}
            size="small"
            className={classes.textField}
            {...register("email")}
          />
        </Box>
        <Box className={classes.box}>
          <Typography className={classes.title}>
            Studio Other Information
          </Typography>

          <TextField
            //id="outlined-basic"
            label="Website Url"
            variant="outlined"
            error={!!errors.websiteUrl}
            helperText={errors?.websiteUrl?.message}
            size="small"
            className={classes.textField}
            {...register("websiteUrl")}
          />

          <TextField
            //id="outlined-basic"
            label="Facebook Link"
            variant="outlined"
            error={!!errors.facebookLink}
            helperText={errors?.facebookLink?.message}
            size="small"
            className={classes.textField}
            {...register("facebookLink")}
          />

          <TextField
            //id="outlined-basic"
            label="Insta Link"
            variant="outlined"
            error={!!errors.instaLink}
            helperText={errors?.instaLink?.message}
            size="small"
            className={classes.textField}
            {...register("instaLink")}
          />
        </Box>
        <Box className={classes.box}>
          <Typography className={classes.title}>
            Certificate Information
          </Typography>

          <Grid
            container
            spacing={3}
            alignItems="center"
            className={classes.gridContainer}
          >
            <Grid item xs={3}>
              <Typography variant="caption" gutterBottom>
                Header Logo
              </Typography>
              <FilePond
                allowMultiple={false}
                maxFiles={1}
                name="headerLogo"
                maxFileSize={"10MB"}
                allowDrop={false}
                allowReorder={true}
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                acceptedFileTypes={["image/*"]}
                server={{
                  process: {
                    url: env.apiBase + "/api/file/uploadimage",
                    onload: (res) => {
                      setValue("headerLogo", res);
                      const _headerLogo = getValues("headerLogo");
                      setFiles({ ...files, headerLogo: _headerLogo });
                    },
                  },
                  fetch: null,
                  revert: null,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <div style={{ maxWidth: "100px" }}>
                <img
                  src={`${
                    files.headerLogo && files.headerLogo !== ""
                      ? files.headerLogo
                      : "/assets/no-image.png"
                  }`}
                  width="100%"
                  alt="logo"
                />
              </div>
            </Grid>
            {!!errors.headerLogo && (
              <Grid item xs={12}>
                <FormHelperText error id="headerLogo-error">
                  {errors?.headerLogo?.message}
                </FormHelperText>
              </Grid>
            )}
          </Grid>

          <Grid
            container
            spacing={3}
            alignItems="center"
            className={classes.gridContainer}
          >
            <Grid item xs={3}>
              <Typography variant="caption" gutterBottom>
                Footer Logo
              </Typography>
              <FilePond
                allowMultiple={false}
                maxFiles={1}
                name="footerLogo"
                maxFileSize={"10MB"}
                allowDrop={false}
                allowReorder={true}
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                acceptedFileTypes={["image/*"]}
                server={{
                  process: {
                    url: env.apiBase + "/api/file/uploadimage",
                    onload: (res) => {
                      setValue("footerLogo", res);
                      const _footerLogo = getValues("footerLogo");
                      setFiles({ ...files, footerLogo: _footerLogo });
                    },
                  },
                  fetch: null,
                  revert: null,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <div style={{ maxWidth: "100px" }}>
                <img
                  src={`${
                    files.footerLogo && files.footerLogo !== ""
                      ? files.footerLogo
                      : "/assets/no-image.png"
                  }`}
                  width="100%"
                  alt="logo"
                />
              </div>
            </Grid>
            {!!errors.footerLogo && (
              <Grid item xs={12}>
                <FormHelperText error id="footerLogo-error">
                  {errors?.footerLogo?.message}
                </FormHelperText>
              </Grid>
            )}
          </Grid>
        </Box>

        <div
          className={classes.buttonContainer}
          style={{ textAlign: "center" }}
        >
          <Button
            variant="contained"
            component={Link}
            size="small"
            to={`/dashboard/studios`}
            disabled={isSubmitting}
            //color="primary"
            //className={classes.button}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: "1rem" }}
            //className={classes.button}
            startIcon={
              isSubmitting && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )
            }
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
}
