import React from "react";
import env from "../../../env";
import { Link, useParams, useHistory } from "react-router-dom";
import { variables } from "../../../cssInJs";
import { useForm  } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import utils from "../../../utils";
import { ErrorMessage, Loading } from "components";
//import LensIcon from "@material-ui/icons/Lens";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";
import {
  makeStyles,
  //AppBar,
  //Tab,
  //Tabs,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  //IconButton,
  //Tooltip,
  //ButtonGroup,
  Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  TextField,
  FormHelperText,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  //DialogActions,
  Grid,
  // List,
  // ListItem,
  // ListItemIcon,
  // ListItemText,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  margin_div: {
    margin: theme.spacing(1),
    display: "inline-block",
    //width: "-webkit-fill-available",
  },
  formRoot: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor": {
      margin: theme.spacing(1),
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },
  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },
  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
    "& .react-datepicker-popper": {
      zIndex: "2",
    },
  },
  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const useVModeStyles = makeStyles((theme) => ({
  boxEditMode: {
    backgroundColor: "white",
    padding: theme.spacing(3),
  },
  hr: {
    borderColor: "rgba(0,0,0,.1)",
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  main_title: { marginBottom: theme.spacing(2) },
  viewTitle: {},
  viewContents: {},
  list: {
    "& .MuiListItemIcon-root": {
      minWidth: "20px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      width: "10px",
    },
  },
}));

const ViewMode = (props) => {
  const classes = useVModeStyles();
  const { privateClass, changeViewMode, studioId } = props;
  const lastUpdated = utils.site.formatDDMMYYYYWithTime(
    new Date(privateClass.dateUpdated)
  );

  return (
    <Box className={classes.boxEditMode}>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item style={{ flexGrow: "1" }}>
          <Typography>
            <DescriptionIcon /> Private Class
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              size="small"
              startIcon={<AttachFileIcon />}
              onClick={changeViewMode}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={`/dashboard/studio/${studioId}/private-classes`}
            >
              Back
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            First Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {privateClass.firstName}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Last Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {privateClass.lastName}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Price:
          </Typography>
          <Typography className={classes.viewContents}>
            {privateClass.price}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Date:
          </Typography>
          <Typography className={classes.viewContents}>
            {utils.site.formatDDMMYYYY(new Date(privateClass.date))}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Start Time:
          </Typography>
          <Typography className={classes.viewContents}>
            {privateClass.startTime}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            End Time:
          </Typography>
          <Typography className={classes.viewContents}>
            {privateClass.endTime}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const schema = yup.object().shape({
  programId: yup.string().required("Program is required."),
  instructorId: yup.string().required("Instructor is required."),
  termId: yup.string().required("Term is required."),
  price: yup.number().required("Price is required.").moreThan(-1).integer(),
  date: yup.date().required("Date is required."),
  startTime: yup.string().required("Start Time is required."),
  endTime: yup.string().required("End Time is required."),
});

export function PrivateClass() {
  const { studioId, privateClassId } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const previousController = React.useRef();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [getPCResult, setGetPCResult] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [autoValue, setAutoValue] = React.useState({
    program: null,
    instructor: null,
    term: null,
  });

  console.log("studioId", studioId);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    //control,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { date } = getValues();

  const inputAutoChange = (event, id, id2, value1, value2) => {
    setValue(id, value1);
    trigger(id);
    setAutoValue({
      ...autoValue,
      [id2]: value2,
    });
  };

  const onChnageDate = (date) => {
    setValue("date", date);
    trigger("date");
  };

  const changeViewMode = () => {
    getPCResult.editMode = false;
    setGetPCResult({ ...getPCResult });
  };

  async function submit(dataModel) {
    setServerErrors([]);
    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/classes/saveprivateclass", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...dataModel,
          privateClassId: privateClassId,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }
      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
        setServerErrors(data.errors);
      } else {
        history.push(`/dashboard/studio/${studioId}/private-classes`);
        //window.location.reload();
      }
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  }

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/classes/getprivateclass", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studioId: studioId,
            privateClassId: privateClassId 
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results) {
          const { programs, instructors, terms, privateClass } = data.results;

          let editMode = false;
          let newBatch = true;

          if (privateClass) {
            editMode = true;
            newBatch = false;

            reset({
              programId: privateClass.programId,
              instructorId: privateClass.instructorId,
              termId: privateClass.termId,
              price: privateClass.price,
              date: new Date(privateClass.date),
              startTime: privateClass.startTime,
              endTime: privateClass.endTime,
            });

            autoValue.program = programs.find(
              (x) => x.programId === privateClass.programId
            );
            autoValue.instructor = instructors.find(
              (x) => x.instructorid === privateClass.instructorid
            );
            autoValue.term = terms.find(
              (x) => x.termId === privateClass.termId
            );

            setAutoValue({
              ...autoValue,
            });
          }

          setGetPCResult({
            ...data.results,
            editMode,
            newBatch,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }

  return (
    <div className={classes.root}>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <img src="/assets/loader.36c5450235c205d74679.gif" alt="loader" />
      </Backdrop>
      <Paper className={classes.paper}>
        {getPCResult && getPCResult.editMode === true ? (
          <ViewMode
            {...getPCResult}
            changeViewMode={changeViewMode}
            studioId={studioId}
          />
        ) : (
          <>
            <Typography className={classes.margin_div}>
              <DateRangeIcon /> Private Lesson
            </Typography>
            <form
              method="POST"
              noValidate=""
              className={classes.formRoot}
              onSubmit={handleSubmit(submit)}
            >
              <Autocomplete
                id="programId"
                //disabled={!checkCreateBatch}
                options={getPCResult.programs || []}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.programName}
                getOptionSelected={(option, value) =>
                  option.programId === value.programId
                }
                value={autoValue.program || null}
                onChange={(event, newValue) => {
                  inputAutoChange(
                    event,
                    "programId",
                    "program",
                    newValue ? newValue.programId : null,
                    newValue
                  );
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.programName}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Program*"
                    variant="outlined"
                    size="small"
                    error={!!errors.programId}
                    helperText={errors?.programId?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              <Autocomplete
                id="instructorId"
                //disabled={!checkCreateBatch}
                options={getPCResult.instructors || []}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.firstName}
                getOptionSelected={(option, value) =>
                  option.userId === value.userId
                }
                value={autoValue.instructor || null}
                onChange={(event, newValue) => {
                  inputAutoChange(
                    event,
                    "instructorId",
                    "instructor",
                    newValue ? newValue.userId : null,
                    newValue
                  );
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.firstName}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Instructor*"
                    variant="outlined"
                    size="small"
                    error={!!errors.instructorId}
                    helperText={errors?.instructorId?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              <Autocomplete
                id="termId"
                //disabled={!checkCreateBatch}
                options={getPCResult.terms || []}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.termName}
                getOptionSelected={(option, value) =>
                  option.termId === value.termId
                }
                value={autoValue.term || null}
                onChange={(event, newValue) => {
                  inputAutoChange(
                    event,
                    "termId",
                    "term",
                    newValue ? newValue.termId : null,
                    newValue
                  );
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.termName}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Term*"
                    variant="outlined"
                    size="small"
                    error={!!errors.termId}
                    helperText={errors?.termId?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              <TextField
                label="Price*"
                variant="outlined"
                error={!!errors.price}
                helperText={errors?.price?.message}
                size="small"
                className={classes.textField}
                type="number"
                defaultValue={0}
                {...register("price")}
              />

              <div className={classes.datePicker}>
                <Typography variant="caption">Date*</Typography>
                <DatePicker
                  selected={date}
                  onChange={onChnageDate}
                  showIcon
                  isClearable
                  dateFormat="dd/MM/yyyy"
                  placeholderText={"dd/mm/yyyy"}
                  toggleCalendarOnIconClick
                />
                {!!errors.date && (
                  <FormHelperText error id="date-error">
                    {errors?.date?.message}
                  </FormHelperText>
                )}
              </div>
              <div className={classes.textField} />
              <TextField
                //id="date"
                label="Start Time*"
                variant="outlined"
                error={!!errors.startTime}
                helperText={errors?.startTime?.message}
                size="small"
                className={classes.textField}
                type="time"
                style={{ maxWidth: "250px" }}
                //defaultValue="2017-05-24"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={
                  {
                    //step: 300, // 5 min
                  }
                }
                {...register("startTime")}
              />
              <TextField
                //id="date"
                label="End Time*"
                variant="outlined"
                error={!!errors.endTime}
                helperText={errors?.endTime?.message}
                size="small"
                className={classes.textField}
                type="time"
                style={{ maxWidth: "250px" }}
                //defaultValue="2017-05-24"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={
                  {
                    //step: 300, // 5 min
                  }
                }
                {...register("endTime")}
              />

              <div
                className={classes.buttonContainer}
                style={{ textAlign: "center" }}
              >
                <Button
                  variant="contained"
                  component={Link}
                  size="small"
                  to={`/dashboard/studio/${studioId}/private-classes`}
                  disabled={isSubmitting}
                  //color="primary"
                  //className={classes.button}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: "1rem" }}
                  //className={classes.button}
                  startIcon={
                    isSubmitting && (
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )
                  }
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        )}
      </Paper>
    </div>
  );
}
