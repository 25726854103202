import React from "react";
import { variables } from "../../../cssInJs";

import {
  AppBar,
  Tab,
  Tabs,
  //Toolbar,
  //lighten,
  makeStyles,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  //Typography,
  //Paper,
  //Checkbox,
  //IconButton,
  //Tooltip,
  //ButtonGroup,
  //Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  //TextField,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  //Box,
  //DialogActions,
  Grid,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  bottom: {
    position: "fixed",
    bottom: "0",
    left: "0",
    textAlign : 'center',
    width: "calc(100% - 270px)",
    marginLeft: "270px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export function StudentBottom(props) {
  const classes = useStyles();
  return (
    <div className={classes.bottom}>
      <Grid container spacing={3}>
        <Grid item xs={3} className={classes.leftGrid}></Grid>
        <Grid item xs={9} style={{backgroundColor : 'red'}}></Grid>
      </Grid>
    </div>
  );
}