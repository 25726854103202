import React from "react";
import env from "../../../env";
import { variables } from "../../../cssInJs";
import { StudentAppBar } from "./studentAppBar";
import { ErrorMessage, Loading } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//import clsx from "clsx";
import * as yup from "yup";
//import { useTheme } from "@material-ui/core/styles";
//import utils from "utils";
import DatePicker from "react-datepicker";
import { StudentView } from "./studentView";
//import { StudentBottom } from "./studentBottom";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import AddIcon from "@material-ui/icons/Add";
//import FilterListIcon from "@material-ui/icons/FilterList";
//import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
//import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
//import CircularProgress from "@material-ui/core/CircularProgress";
//import DeleteIcon from "@material-ui/icons/Delete";
import DateRangeIcon from "@material-ui/icons/DateRange";

import {
  Link,
  useHistory,
  //useLocation,
  useParams,
} from "react-router-dom";
import {
  //Toolbar,
  //lighten,
  makeStyles,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  Typography,
  Paper,
  Checkbox,
  //IconButton,
  //Tooltip,
  //ButtonGroup,
  Button,
  FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  TextField,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  //DialogActions,
  Grid,
  FormControlLabel,
  FormHelperText,
  //Radio,
  //List,
  //ListItem,
  //ListItemIcon,
  //ListItemText,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: "transparent",
  },
  gridCon: {
    padding: theme.spacing(3),
    backgroundColor: "white",
    marginTop: theme.spacing(1),
  },
  hr: {
    marginBottom: "0",
    marginTop: theme.spacing(1),
    borderColor: "rgba(0,0,0,.1)",
  },
  typoBox2: {
    marginTop: theme.spacing(4),
  },
  typoTitle2: {
    //color: variables.colorGray1,
    //marginTop: theme.spacing(1),
    fontWeight: 700,
  },
  typoContent2: {},
  list: {
    "& .MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
      alignItems: "flex-start",
    },
    "& .MuiListItemIcon-root": {
      marginTop: "5px",
    },
  },
  leftGrid: {
    overflowY: "scroll",
    height: "calc(100vh - 175px)",
  },
  rightGridBox: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    height: "calc(100vh - 175px)",
    overflowY: "auto",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },
  bottom: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "red",
    width: "62%",
    //width: "calc(100% - 240px)",
    //marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const useEditView = makeStyles((theme) => ({
  formRoot: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor": {
      margin: theme.spacing(1),
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },
  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    //width: `-webkit-fill-available`,
    //marginTop: theme.spacing(1),
    //marginBottom: theme.spacing(1),
  },

  marginDiv: {
    margin: theme.spacing(1),
    display: "inline-block",
    //width: "-webkit-fill-available",
  },
  option: {
    fontSize: 14,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },

  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    zIndex: 1,
  },

  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
    "& .react-datepicker-popper" : {
      zIndex: 2,
    }
  },

  box_criteria: {
    display: "flex",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },

  title_criteria: {
    flex: "1 1 100%",
  },

  button_criteria: {
    margin: theme.spacing(1),
  },

  box_field: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },

  textField_field: {
    //width: "-webkit-fill-available",
    flex: "1 1 100%",
  },

  buttonGroup_field: {
    marginLeft: theme.spacing(1),
    maxHeight: "42.38px",
  },

  filePond_field: {
    marginTop: theme.spacing(1),
  },

  paperi: {
    padding: theme.spacing(1),
  },

  hr: {
    borderColor: "rgba(0,0,0,.1)",
    marginTop: "0",
    marginBottom: "0",
    visibility: "hidden",
  },
}));

const StudentViewMemo = React.memo((props) => {
  return <StudentView {...props} />;
});

const schema = yup.object().shape({

  studentLoginEmail: yup
    .string()
    .required("student Login Email is required.")
    .email("Email is required"),
  studentPassword: yup.string().required("Student Password is required."),
  studentConfirmPassword: yup
    .string()
    .required("Student Confirm Password is required."),
  parentLoginEmail: yup
    .string()
    .required("Parent Login Email is required.")
    .email("Email is required"),
  parentPassword: yup.string().required("Parent Password is required."),
  parentConfirmPassword: yup
    .string()
    .required("Parent Confirm Password is required."),
  uniformMeasuredDate: yup
    .date()
    .required("Uniform Measured Date is required."),
  startDate: yup.date().required("Start Date is required."),
  programId: yup.string().required("Program is required."),
  houseTeamId: yup.string().required("House Team is required."),
  beltLevelId: yup.string().required("Belt Level is required."),
  beltSizeId: yup.string().required("Belt Size is required."),
  uniformId: yup.string().required("Uniform is required."),
  gloveId: yup.string().required("Glove is required."),
  tshirtId: yup.string().required("T Shirt is required."),
  advancedUniform: yup.boolean(),
});

const EvaluationEditView = (props) => {
  const classes = useEditView();
  const {
    houseTeams,
    programs,
    register,
    setValue,
    isSubmitting,
    errors,
    trigger,
    autoValue,
    inputAutoChange,
    beltLevels,
    inputChangeProgram,
    uniformMeasuredDate,
    startDate,
    beltSizes,
    uniforms,
    gloves,
    tshirts,
    advancedUniform,
    handleSubmit,
    submit
  } = props;

  return (
    <>
      
      <Typography className={classes.marginDiv}>
        <DateRangeIcon /> Rego2
      </Typography>

      <form
        method="POST"
        noValidate=""
        className={classes.formRoot}
        onSubmit={handleSubmit(submit)}
      >
        <TextField
          label="Student Login Email ID"
          variant="outlined"
          error={!!errors.studentLoginEmail}
          helperText={errors?.studentLoginEmail?.message}
          size="small"
          className={classes.textField}
          {...register("studentLoginEmail")}
        />

        <TextField
          label="Student Password"
          variant="outlined"
          error={!!errors.studentPassword}
          helperText={errors?.studentPassword?.message}
          size="small"
          className={classes.textField}
          type="password"
          {...register("studentPassword")}
        />

        <TextField
          label="Student Confirm Password"
          variant="outlined"
          error={!!errors.studentConfirmPassword}
          helperText={errors?.studentConfirmPassword?.message}
          size="small"
          className={classes.textField}
          type="password"
          {...register("studentConfirmPassword")}
        />

        <hr className={classes.hr} />

        <TextField
          label="Parent Login Email ID"
          variant="outlined"
          error={!!errors.parentLoginEmail}
          helperText={errors?.parentLoginEmail?.message}
          size="small"
          className={classes.textField}
          {...register("parentLoginEmail")}
        />

        <TextField
          label="Parent Password"
          variant="outlined"
          error={!!errors.parentPassword}
          helperText={errors?.parentPassword?.message}
          size="small"
          className={classes.textField}
          type="password"
          {...register("parentPassword")}
        />

        <TextField
          label="Parent Confirm Password"
          variant="outlined"
          error={!!errors.parentConfirmPassword}
          helperText={errors?.parentConfirmPassword?.message}
          size="small"
          className={classes.textField}
          type="password"
          {...register("parentConfirmPassword")}
        />

        <hr className={classes.hr} />

        <div className={classes.datePicker}>
          <Typography variant="caption"> Uniform Measured Date </Typography>
          <DatePicker
            selected={uniformMeasuredDate}
            onChange={(date) => {
              setValue("uniformMeasuredDate", date);
              trigger("uniformMeasuredDate");
            }}
            showIcon
            isClearable
            dateFormat="dd/MM/yyyy"
            placeholderText={"dd/mm/yyyy"}
            toggleCalendarOnIconClick
          />
          {!!errors.dateOfBirth && (
            <FormHelperText error id="startDate-error">
              {errors?.dateOfBirth?.message}
            </FormHelperText>
          )}
        </div>

        <div className={classes.datePicker}>
          <Typography variant="caption"> Start Date </Typography>
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setValue("startDate", date);
              trigger("startDate");
            }}
            showIcon
            isClearable
            dateFormat="dd/MM/yyyy"
            placeholderText={"dd/mm/yyyy"}
            toggleCalendarOnIconClick
          />
          {!!errors.dateOfBirth && (
            <FormHelperText error id="startDate-error">
              {errors?.dateOfBirth?.message}
            </FormHelperText>
          )}
        </div>

        <Autocomplete
          id="programId"
          //disabled={!checkCreateBatch}
          options={programs}
          className={classes.formControl}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option.programName}
          getOptionSelected={(option, value) =>
            option.programName === value.programName
          }
          value={autoValue.program || null}
          onChange={(event, newValue) => {
            inputChangeProgram(event, newValue);
          }}
          renderOption={(option) => (
            <React.Fragment>{option.programName}</React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Program*"
              variant="outlined"
              size="small"
              error={!!errors.programId}
              helperText={errors?.programId?.message}
              inputProps={{
                ...params.inputProps,
                //autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />

        <Autocomplete
          id="houseTeamId"
          //disabled={!checkCreateBatch}
          options={houseTeams}
          className={classes.formControl}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option.houseTeamName}
          getOptionSelected={(option, value) =>
            option.houseTeamName === value.houseTeamName
          }
          value={autoValue.houseTeam || null}
          onChange={(event, newValue) => {
            inputAutoChange(
              event,
              "houseTeamId",
              "houseTeam",
              newValue ? newValue.houseTeamId : null,
              newValue
            );
          }}
          renderOption={(option) => (
            <React.Fragment>{option.houseTeamName}</React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="House Team*"
              variant="outlined"
              size="small"
              error={!!errors.houseTeamId}
              helperText={errors?.houseTeamId?.message}
              inputProps={{
                ...params.inputProps,
                //autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />

        <Autocomplete
          id="beltLevelId"
          options={beltLevels || []}
          className={classes.formControl}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option.beltName}
          getOptionSelected={(option, value) =>
            option.beltName === value.beltName
          }
          value={autoValue.beltLevel || null}
          onChange={(event, newValue) => {
            inputAutoChange(
              event,
              "beltLevelId",
              "beltLevel",
              newValue ? newValue.beltLevelId : null,
              newValue
            );
          }}
          renderOption={(option) => (
            <React.Fragment>{option.beltName}</React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Belt Level*"
              variant="outlined"
              size="small"
              error={!!errors.beltLevelId}
              helperText={errors?.beltLevelId?.message}
              inputProps={{
                ...params.inputProps,
                //autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />

        <Autocomplete
          id="beltSizeId"
          options={beltSizes || []}
          className={classes.formControl}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option.length + ""}
          getOptionSelected={(option, value) => option.length === value.length}
          value={autoValue.beltSize || null}
          onChange={(event, newValue) => {
            inputAutoChange(
              event,
              "beltSizeId",
              "beltSize",
              newValue ? newValue.beltSizeId : null,
              newValue
            );
          }}
          renderOption={(option) => (
            <React.Fragment>{option.length}</React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Belt Size*"
              variant="outlined"
              size="small"
              error={!!errors.beltSizeId}
              helperText={errors?.beltSizeId?.message}
              inputProps={{
                ...params.inputProps,
                //autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />

        <Autocomplete
          id="uniformId"
          options={uniforms || []}
          className={classes.formControl}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option.length + ""}
          getOptionSelected={(option, value) => option.length === value.length}
          value={autoValue.uniform || null}
          onChange={(event, newValue) => {
            inputAutoChange(
              event,
              "uniformId",
              "uniform",
              newValue ? newValue.uniformId : null,
              newValue
            );
          }}
          renderOption={(option) => (
            <React.Fragment>{option.length}</React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Uniform*"
              variant="outlined"
              size="small"
              error={!!errors.uniformId}
              helperText={errors?.uniformId?.message}
              inputProps={{
                ...params.inputProps,
                //autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />

        <Autocomplete
          id="gloveId"
          options={gloves || []}
          className={classes.formControl}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option.value}
          getOptionSelected={(option, value) => option.value === value.value}
          value={autoValue.glove || null}
          onChange={(event, newValue) => {
            inputAutoChange(
              event,
              "gloveId",
              "glove",
              newValue ? newValue.gloveId : null,
              newValue
            );
          }}
          renderOption={(option) => (
            <React.Fragment>{option.value}</React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Glove*"
              variant="outlined"
              size="small"
              error={!!errors.gloveId}
              helperText={errors?.gloveId?.message}
              inputProps={{
                ...params.inputProps,
                //autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />

        <Autocomplete
          id="tshirtId"
          options={tshirts || []}
          className={classes.formControl}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option.value}
          getOptionSelected={(option, value) => option.value === value.value}
          value={autoValue.tshirt || null}
          onChange={(event, newValue) => {
            inputAutoChange(
              event,
              "tshirtId",
              "tshirt",
              newValue ? newValue.tshirtId : null,
              newValue
            );
          }}
          renderOption={(option) => (
            <React.Fragment>{option.value}</React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tshirt*"
              variant="outlined"
              size="small"
              error={!!errors.tshirtId}
              helperText={errors?.tshirtId?.message}
              inputProps={{
                ...params.inputProps,
                //autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />

        <FormControl
          error={!!errors.advancedUniform}
          component="fieldset"
          className={classes.formControl}
          size="small"
        >
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                {...register("advancedUniform")}
                checked={advancedUniform}
              />
            }
            className={`${
              !!errors.advancedUniform ? "Mui-error" : "Mui-error-non"
            }`}
            label="Advanced Uniform"
          />
        </FormControl>

        <div
          className={classes.buttonContainer}
          style={{ textAlign: "center" }}
        >
          <Button
            variant="contained"
            component={Link}
            size="small"
            to={`/dashboard/property-metrics/setup-tshirts`}
            disabled={isSubmitting}
            //color="primary"
            //className={classes.button}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: "1rem" }}
            //className={classes.button}
            startIcon={
              isSubmitting && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )
            }
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export function StudentRego() {
  const classes = useStyles();
  const { studentId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [getstudentDetailRe, setGetstudentDetailRe] = React.useState();
  const [backdropOpen, setBackdropOpen] = React.useState(false);

  const previousController = React.useRef(null);
  const timerController = React.useRef();

  const [autoValue, setAutoValue] = React.useState({
    houseTeam: null,
    program: null,
    beltLevel: null,
    beltSize: null,
    uniform: null,
    glove: null,
    tshirt: null,
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    //control,
    watch,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { advancedUniform: false },
  });

  const { uniformMeasuredDate, startDate, advancedUniform } = getValues();

  const inputAutoChange = (event, id, id2, newValue, newValue2) => {
    setValue(id, newValue);
    trigger(id);
    setAutoValue({
      ...autoValue,
      [id2]: newValue2,
    });
  };

  const inputChangeProgram = async (event, newValue) => {
    
    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/belt/getonlybeltlevels", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          programId: newValue.programId,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();
      
      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      }

      if (data.results && data.results.getSetupBeltLevelsRe) {
        // setGetStudentResult({
        //   ...getStudentResult,
        //   cityList: data.results.cityList,
        // });
        const _beltLevels = data.results.getSetupBeltLevelsRe.beltLevels;
        setGetstudentDetailRe({
          ...getstudentDetailRe,
          beltLevels: _beltLevels,
        });
      }

      timerController.current = setTimeout(() => {
        setBackdropOpen(false);
      }, 500);
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }

    setValue("programId", newValue.programId);
    setValue("beltLevelId", "");
    trigger("programId");

    setAutoValue({
      ...autoValue,
      program: newValue,
      beltLevel: null,
    });
  };

  const submit = async (dataModel) => {
    
    setServerErrors([]);
    setBackdropOpen(true);
    
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/student/savestudentrego", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          studentId: studentId,
          ...dataModel,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }
      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
        setServerErrors(data.errors);
      } else {
        history.push(`/dashboard/student-detail/${studentId}`);
      }
    } catch (error) {
      //console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }


  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/student/getstudentrego", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studentId: studentId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getstudentDetailRe) {
          
          let editMode = false;
          let newBatch = true;

          const _studentRego = data.results.getstudentDetailRe.studentRego;
          const _houseTeams = data.results.getstudentDetailRe.houseTeams;
          const _programs = data.results.getstudentDetailRe.programs;
          const _beltLevels = data.results.getstudentDetailRe.beltLevels;
          const _beltSizes = data.results.getstudentDetailRe.beltSizes;
          const _uniforms = data.results.getstudentDetailRe.uniforms;
          const _gloves = data.results.getstudentDetailRe.gloves;
          const _tshirts = data.results.getstudentDetailRe.tshirts;

          if(_studentRego){
            
            editMode = true;
            newBatch = false;

            reset({
              studentLoginEmail: _studentRego.studentLoginEmail,
              parentLoginEmail: _studentRego.parentLoginEmail,
              uniformMeasuredDate: new Date(_studentRego.uniformMeasuredDate),
              startDate: new Date(_studentRego.startDate),
              programId : _studentRego.programId,
              houseTeamId : _studentRego.houseTeamId,
              beltLevelId : _studentRego.beltLevelId,
              beltSizeId : _studentRego.beltSizeId,
              uniformId : _studentRego.uniformId,
              gloveId : _studentRego.gloveId,
              tshirtId : _studentRego.tshirtId,
              advancedUniform : _studentRego.advancedUniform
            });

            autoValue.program = _programs.find(x => x.programId === _studentRego.programId);
            autoValue.houseTeam = _houseTeams.find(x => x.houseTeamId === _studentRego.houseTeamId);
            autoValue.beltLevel = _beltLevels.find(x => x.beltLevelId === _studentRego.beltLevelId);
            autoValue.beltSize = _beltSizes.find(x => x.beltSizeId === _studentRego.beltSizeId);
            autoValue.uniform = _uniforms.find(x => x.uniformId === _studentRego.uniformId);
            autoValue.glove = _gloves.find(x => x.gloveId === _studentRego.gloveId);
            autoValue.tshirt = _tshirts.find(x => x.tshirtId === _studentRego.tshirtId);

            setAutoValue({
              ...autoValue
            });

          }

          setGetstudentDetailRe({
            ...data.results.getstudentDetailRe,
            editMode,
            newBatch
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "advancedUniform") {
        trigger(name);
      }
    });
    return () => subscription.unsubscribe();

    // eslint-disable-next-line
  }, [watch]);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <img src="/assets/loader.36c5450235c205d74679.gif" alt="loader" />
      </Backdrop>
      <Paper className={classes.paper} elevation={0}>
        <StudentAppBar tab={"rego2"} studentId={studentId} />
        <div className={classes.gridCon}>
          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.leftGrid}>
              <StudentViewMemo {...getstudentDetailRe} />
            </Grid>

            <Grid item xs={9}>
              <Box className={classes.rightGridBox}>
                <EvaluationEditView
                  {...getstudentDetailRe}
                  uniformMeasuredDate={uniformMeasuredDate}
                  advancedUniform = {advancedUniform}
                  startDate={startDate}
                  isSubmitting={isSubmitting}
                  errors={errors}
                  autoValue={autoValue}
                  trigger={trigger}
                  register={register}
                  setValue={setValue} 
                  inputAutoChange={inputAutoChange}
                  inputChangeProgram={inputChangeProgram}
                  handleSubmit = {handleSubmit}
                  submit = {submit}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
        {/* <StudentBottom /> */}
      </Paper>
    </div>
  );
}