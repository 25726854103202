import React from "react";
import { useStore } from "../../../store";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Box } from "@material-ui/core";
import {
  //useParams,
  Link,
} from "react-router-dom";
import { ConfigMenuStudio } from "./configMenu";
import { StudioMenuStudio } from "./studioMenu";
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useLocation,
} from "react-router-dom";
import { Programs } from "./programs";
import { Program } from "./program";
import { Terms } from "./terms";
import { Term } from "./term";
import { Batches } from "./batches";
import { Batche } from "./batche";
import { BeltLevels } from "./beltLevels";
import { BeltLevel } from "./beltLevel";
import { HouseTeams } from "./houseTeams";
import { StudioSetting } from "./studioSetting";
import { Dashboard } from "./dashboard";
import { Awards } from "./awards";
import { Award } from "./award";
import { Classes } from "./classes";
import { ClassDetails } from "./classDetails";
import { ClassHistory } from "./classHistory";
import { Classlist } from "./classList";
import { PrivateLessons } from "./privateLessons";
import { PrivateClass } from "./PrivateLesson";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    "&:focus": {
      color: "inherit",
      //opacity: '0.7'
    },
  },
}));

export function StudioSection() {
  const { dispatch } = useStore();
  const classes = useStyles();
  const [value, setValue] = React.useState("one");
  const { studioId } = useParams();
  const pathname = useLocation().pathname;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/member/dashboard" },
          { title: "Studios", link: "/member/dashboard/studios" },
          { title: "Studio", link: "" },
        ],
      },
    });

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
            //indicatorColor="primary"
            //variant="fullWidth"
          >
            <Tab
              value="one"
              wrapped
              label="Config Menu"
              component={Link}
              to={`/dashboard/studio/${studioId}/dashboard`}
              className={classes.tab}
              {...a11yProps("one")}
            />
            <Tab
              value="two"
              wrapped
              label="Studio Menu"
              component={Link}
              to={`/dashboard/studio/${studioId}/classes`}
              className={classes.tab}
              {...a11yProps("two")}
            />
            <Tab
              value="three"
              wrapped
              label="Reports"
              className={classes.tab}
              {...a11yProps("three")}
            />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index="one">
          <ConfigMenuStudio studioId={studioId} pathname={pathname} />
        </TabPanel>
        <TabPanel value={value} index="two">
          <StudioMenuStudio studioId={studioId} pathname={pathname} />
        </TabPanel>
        <TabPanel value={value} index="three">
          Item Three
        </TabPanel>
      </div>
      <Box mt={2} mb={2}>
        <Switch>
          <Route
            exact
            path="/dashboard/studio/:studioId/private-class/new"
            component={PrivateClass}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/private-class/:privateClassId"
            component={PrivateClass}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/private-classes"
            component={PrivateLessons}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/class-list"
            component={Classlist}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/class-history"
            component={ClassHistory}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/class/:classId"
            component={ClassDetails}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/classes"
            component={Classes}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/dashboard"
            component={Dashboard}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/program/new"
            component={Program}
          />
          <Route
            exact
            path="/dashboard/studio/:studioId/program/:programId"
            component={Program}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/programs"
            component={Programs}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/term/new"
            component={Term}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/term/:termId"
            component={Term}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/terms"
            component={Terms}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/batche/new"
            component={Batche}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/batche/:batcheId"
            component={Batche}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/batches"
            component={Batches}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/beltLevel/:beltLevelId"
            component={BeltLevel}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/beltLevels"
            component={BeltLevels}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/houseTeams"
            component={HouseTeams}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/studio-setting"
            component={StudioSetting}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/award/:awardId"
            component={Award}
          />

          <Route
            exact
            path="/dashboard/studio/:studioId/awards"
            component={Awards}
          />

          <Route exact path="/dashboard/studio/:studioId">
            <Redirect to={`/dashboard/studio/${studioId}/dashboard`} />
          </Route>

          <Route path="/dashboard">
            <Redirect to="/dashboard" status="301" />
          </Route>
        </Switch>
      </Box>
    </>
  );
}
