import React from 'react';

export const ErrorMessage = ({ errors, summaryMessage = null }) => {
  if (!errors) return null;
  if (typeof errors.length !== 'undefined' && errors.length === 0) return null;

  if (summaryMessage)
    return (
      <div className='error' role='alert'>
        {summaryMessage}
      </div>
    );
  let message = errors;
  if (Array.isArray(errors)) {
    if (errors.length === 0) return null;
    //message = errors.map((error, index) => <div key={index}>{error}</div>);
    message = errors.map((error, index) => {
        if(typeof error === 'object' && error.errorMessage && error.errorMessage !== ''){
            return(
                <div key={index}>{error.errorMessage}</div>
            )
        }
        return(
            <div key={index}>{error}</div>
        )
    });
  }

  return (
    <div className='error' role='alert'>
      {message}
    </div>
  );
};