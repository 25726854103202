import React from "react";
import { variables } from "../../../cssInJs";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, Link, useHistory } from "react-router-dom";
import { ErrorMessage, Loading } from "components";
import env from "../../../env";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {  makeStyles,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  //IconButton,
  //Tooltip,
  //ButtonGroup,
  Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  TextField,
  FormHelperText,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  //Box,
  //DialogActions,
  Grid,
  //Backdrop,
} from "@material-ui/core";

registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginImageTransform);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  formRoot: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor": {
      margin: theme.spacing(1),
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },
  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  marginDiv: {
    margin: theme.spacing(1),
  },
  option: {
    fontSize: 14,
  },

  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
  },
}));

const schema = yup.object().shape({
  dateFormat: yup.number().required("Date Format is required."),
  privateLessonPriceMin: yup
    .number()
    .required("private Lesson PriceMin is required.")
    .positive()
    .integer(),
  privateLessonPriceMax: yup
    .number()
    .required("private Lesson PriceMax is required.")
    .positive()
    .integer(),
  maxBirthdayParty: yup
    .number()
    .required("Max Birthday Party is required.")
    .positive(),
  emailSignature: yup.string().required("EmailSignature is required."),
  signatureImage: yup.string().nullable(true),
  signatureInformation: yup.string().nullable(true),
  signatureSubheadingTitle: yup.string().nullable(true),
  signatureSubheadingValue: yup.string().nullable(true),
  gradingDate: yup.date().nullable(true),
});

export function StudioSetting() {
  const classes = useStyles();
  const history = useHistory();
  const { studioId } = useParams();
  const previousController = React.useRef();
  //const timerController = React.useRef();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [getStudioSettingResult, setGetStudioSettingResult] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [autoValue, setAutoValue] = React.useState({
    dateFormat: null,
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: { country: ""},
  });

  const { signatureImage, emailSignature, gradingDate } = getValues();

  async function submit(dataModel) {

    setServerErrors([]);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/studio/savestudiosetting", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          studioId: studioId,
          ...dataModel,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }
      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      } else {
        history.push(`/dashboard/studio/${studioId}/terms`);
        //window.location.reload();
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  const inputAutoChange = (event, newValue, id, value) => {
    setValue(id, value);
    trigger(id);
    setAutoValue({
      ...autoValue,
      [id]: newValue,
    });
  };

  const onChnageGradingDate = (date) => {
    setValue("gradingDate", date);
    trigger("gradingDate");
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/studio/getstudiosetting", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studioId: studioId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }
        const data = await res.json();
        
        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getStudioSettingResult) {
          if (data.results.getStudioSettingResult.studioSetting) {
            const studioSetting =
              data.results.getStudioSettingResult.studioSetting;
            const _dateFormatList =
              data.results.getStudioSettingResult.dateFormatList;

            const _dateFormat = _dateFormatList.find(
              (x) => x.value === studioSetting.dateFormat
            );

            setValue("dateFormat", studioSetting.dateFormat);
            setValue(
              "privateLessonPriceMin",
              studioSetting.privateLessonPriceMin
            );
            setValue(
              "privateLessonPriceMax",
              studioSetting.privateLessonPriceMax
            );
            setValue("maxBirthdayParty", studioSetting.maxBirthdayParty);
            setValue("emailSignature", studioSetting.emailSignature);
            setValue("signatureImage", studioSetting.signatureImage ? studioSetting.signatureImage : "");
            setValue(
              "signatureInformation",
              studioSetting.signatureInformation
            );
            setValue(
              "signatureSubheadingTitle",
              studioSetting.signatureSubheadingTitle
            );
            setValue(
              "signatureSubheadingValue",
              studioSetting.signatureSubheadingValue
            );
            setValue(
              "gradingDate",
              studioSetting.gradingDate
                ? new Date(studioSetting.gradingDate)
                : null
            );

            trigger();

            setAutoValue({
              ...autoValue,
              dateFormat: _dateFormat,
            });
          }
          setGetStudioSettingResult(data.results.getStudioSettingResult);
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);
  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form
          method="POST"
          noValidate=""
          className={classes.formRoot}
          onSubmit={handleSubmit(submit)}
        >
          <Autocomplete
            id="dateFormat"
            className={classes.formControl}
            options={getStudioSettingResult.dateFormatList}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            value={autoValue.dateFormat || null}
            onChange={(event, newValue) => {
              inputAutoChange(
                event,
                newValue,
                "dateFormat",
                newValue ? newValue.value : null
              );
            }}
            renderOption={(option) => (
              <React.Fragment>{option.name}</React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Date Format*"
                variant="outlined"
                size="small"
                error={!!errors.dateFormat}
                helperText={errors?.dateFormat?.message}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
          <TextField
            //id="outlined-basic"
            label="Private Lesson Price Min*"
            variant="outlined"
            error={!!errors.privateLessonPriceMin}
            helperText={errors?.privateLessonPriceMin?.message}
            size="small"
            className={classes.textField}
            type="number"
            {...register("privateLessonPriceMin")}
          />
          <TextField
            //id="outlined-basic"
            label="Private Lesson Price Max*"
            variant="outlined"
            error={!!errors.privateLessonPriceMax}
            helperText={errors?.privateLessonPriceMax?.message}
            size="small"
            className={classes.textField}
            type="number"
            {...register("privateLessonPriceMax")}
          />
          <TextField
            //id="outlined-basic"
            label="Max Birthday Party*"
            variant="outlined"
            error={!!errors.maxBirthdayParty}
            helperText={errors?.maxBirthdayParty?.message}
            size="small"
            className={classes.textField}
            type="number"
            {...register("maxBirthdayParty")}
          />
          <Typography className={classes.marginDiv}>
            {" "}
            Email Signature{" "}
          </Typography>
          <CKEditor
            editor={ClassicEditor}
            config={{
              //toolbar: ['heading', '|', 'fontSize'],
              toolbar: [
                "heading",
                "|",
                "bold",
                "italic",
                "link",
                "|",
                "bulletedList",
                "numberedList",
              ],
            }}
            data={emailSignature}
            onInit={(editor) => {
              //console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setValue("emailSignature", data);
              trigger("emailSignature");
              //ddd.onChange(data);
              //console.log({ event, editor, data });
            }}
            // onBlur={(event, editor) => {
            //   console.log("Blur.", editor);
            // }}
            // onFocus={(event, editor) => {
            //   console.log("Focus.", editor);
            // }}
          />

          <Grid
            container
            //spacing={3}
            alignItems="center"
            className={classes.marginDiv}
          >
            <Grid item xs={6}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={6}>
                  <Typography> Signature Image </Typography>
                  <FilePond
                    allowMultiple={false}
                    maxFiles={1}
                    name="signatureImage"
                    maxFileSize={"10MB"}
                    allowDrop={false}
                    allowReorder={true}
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    acceptedFileTypes={["image/*"]}
                    server={{
                      process: {
                        url: env.apiBase + "/api/file/uploadimage",
                        onload: (res) => {
                          setValue("signatureImage", res);
                          trigger("signatureImage");
                        },
                      },
                      fetch: null,
                      revert: null,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <div style={{ maxWidth: "100px" }}>
                    <img
                      src={`${
                        signatureImage && signatureImage !== ""
                          ? signatureImage
                          : "/assets/no-image.png"
                      }`}
                      width="100%"
                      alt="logo"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextField
                //id="outlined-basic"
                label="Signature Information"
                variant="outlined"
                error={!!errors.signatureInformation}
                helperText={errors?.signatureInformation?.message}
                size="small"
                style={{ width: "100%" }}
                //className={classes.textField}
                multiline
                minRows={3}
                {...register("signatureInformation")}
              />
            </Grid>
          </Grid>

          <TextField
            //id="outlined-basic"
            label="Signature Subheading Title"
            variant="outlined"
            error={!!errors.signatureSubheadingTitle}
            helperText={errors?.signatureSubheadingTitle?.message}
            size="small"
            className={classes.textField}
            {...register("signatureSubheadingTitle")}
          />

          <TextField
            //id="outlined-basic"
            label="Signature Subheading Value"
            variant="outlined"
            error={!!errors.signatureSubheadingValue}
            helperText={errors?.signatureSubheadingValue?.message}
            size="small"
            className={classes.textField}
            {...register("signatureSubheadingValue")}
          />

          <div className={classes.datePicker}>
            <Typography variant="caption">
              {" "}
              Certificate Grading Date{" "}
            </Typography>
            <DatePicker
              selected={gradingDate}
              onChange={onChnageGradingDate}
              showIcon
              isClearable
              dateFormat="dd/MM/yyyy"
              placeholderText={"dd/mm/yyyy"}
              toggleCalendarOnIconClick
            />
            {!!errors.gradingDate && (
              <FormHelperText error id="startDate-error">
                {errors?.gradingDate?.message}
              </FormHelperText>
            )}
          </div>

          {/* <TextField
            //id="outlined-basic"
            label="Signature Subheading Value"
            variant="outlined"
            error={!!errors.signatureSubheadingValue}
            helperText={errors?.signatureSubheadingValue?.message}
            size="small"
            className={classes.textField}
            {...register("signatureSubheadingValue")}
          /> */}

          <div
            className={classes.buttonContainer}
            style={{ textAlign: "center" }}
          >
            <Button
              variant="contained"
              component={Link}
              size="small"
              to={`/dashboard/studios`}
              disabled={isSubmitting}
              //color="primary"
              //className={classes.button}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: "1rem" }}
              //className={classes.button}
              startIcon={
                isSubmitting && (
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )
              }
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
}
