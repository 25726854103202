import utils from '../../utils';
//import siteConfig from '../../config';

export async function fetchGet(url, signal, apiNetJwt) {
  //console.log(url, options);
  const options = {}
  apiNetJwt && await apiNetJwt(options);

  const res = await fetch(url, { 
    method: 'GET', 
    signal,
    ...options
  });
  //console.log(res);

  if (!res.ok) {
    try {
      const json = await res.json();
      console.log(json);

      if (json.message) {
        throw new Error(json.message)
      }
    } catch (e) {
      throw e;
    }
    const statusText = res.status === 401 ? 'Permission denied. Please login first.' : res.statusText
    throw new Error('Error - [' + res.status + ']' + res.status + (statusText && (' - ' + statusText)))
  }

  //const json = await res.json();
  const text = await res.text();
  const json = text && text.length ? JSON.parse(text) : null;
  //console.log(json);

  // check if ajax result from c#
  if (json && json.errors && json.errors.length > 0) {
    const errMessages = utils.form.getErrors(json);
    throw new Error(errMessages.join(', '))
  }
  return json;
}


export async function fetchPost(url, data, signal, apiNetJwt) {
  //console.log(url, options);
  const options = { headers: { 'Content-Type': 'application/json' } }
  apiNetJwt && await apiNetJwt(options);

  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    signal,
    ...options
  });

  if (!res.ok) {
    try {
      const json = await res.json();
      console.log(json);

      if (json.message) {
        throw new Error(json.message)
      }
    } catch (e) { }
    throw new Error('Error - ' + res.status + (res.statusText && (' - ' + res.statusText)))
  }

  //const json = await res.json();
  const text = await res.text();
  const json = text && text.length ? JSON.parse(text) : null;
  //console.log(json);

  // check if ajax result from c#
  if (json && json.errors && json.errors.length > 0) {
    const errMessages = utils.form.getErrors(json);
    throw new Error(errMessages.join(', '))
  }
  return json;
}