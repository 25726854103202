import React from "react";
//import { useForm } from "react-hook-form";
//import { variables } from "../../../cssInJs";
import { useParams, Link } from "react-router-dom";
import { Loading, ErrorMessage } from "components";
import env from "../../../env";
import DescriptionIcon from "@material-ui/icons/Description";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import utils from "../../../utils";
import {
  AppBar,
  Tab,
  Tabs,
  //Toolbar,
  //lighten,
  makeStyles,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  //IconButton,
  //Tooltip,
  //ButtonGroup,
  Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  //TextField,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  //DialogActions,
  Grid,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

export function BeltLevel() {
  const { studioId, beltLevelId } = useParams();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const previousController = React.useRef();
  const [getBeltlevelResult, setGetBeltlevelResult] = React.useState();
  const [currentTab, setCurrentTab] = React.useState();
  const classes = useStyles();

  React.useEffect(() => {
    
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/belt/getbeltlevel", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studioId: studioId,
            beltLevelId: beltLevelId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }
        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getBeltlevelResult) {
          const _beltLevel = data.results.getBeltlevelResult.beltLevel;
          let editMode = false;
          let checkNew = true;
          if (_beltLevel) {
            editMode = true;
            checkNew = false;
          }

          setGetBeltlevelResult({
            editMode: editMode,
            checkNew: checkNew,
            ...data.results.getBeltlevelResult,
          });
          setCurrentTab(data.results.getBeltlevelResult.programId);
        }
      } catch (error) {
        //console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }

      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <AppBar position="static">
          <Tabs
            value={currentTab}
            //onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            {getBeltlevelResult.programs.map((program, index) => {
              return (
                <Tab
                  key={program.programId}
                  value={program.programId}
                  label={program.programName}
                  {...a11yProps(program.programId)}
                  component={Link}
                  to={`/dashboard/studio/${studioId}/beltLevels?programIdParm=${program.programId}`}
                />
              );
            })}
          </Tabs>
        </AppBar>

        {getBeltlevelResult && getBeltlevelResult.editMode === true && (
          <GetViewMode
            studioId={studioId}
            getBeltlevelResult={getBeltlevelResult}
          />
        )}
      </Paper>
    </div>
  );
}

const useGVModeStyles = makeStyles((theme) => ({
  boxEditMode: {
    backgroundColor: "white",
    padding: theme.spacing(1),
  },
  hr: {
    borderColor: "rgba(0,0,0,.1)",
  },
  viewTitle: {},
  viewContents: {},
}));

function GetViewMode(props) {
  const classes = useGVModeStyles();
  const { studioId, getBeltlevelResult } = props;
  const { beltLevel, programId } = getBeltlevelResult;
  const displayDateUpdated = utils.site.formatDDMMYYYYWithTime(
    new Date(beltLevel.dateUpdated)
  );

  return (
    <Box className={classes.boxEditMode}>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item style={{ flexGrow: "1" }}>
          <Typography>
            <DescriptionIcon /> Belt Lebel Details
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle2}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={`/dashboard/studio/${studioId}/beltLevels?programIdParm=${programId}`}
            >
              Back
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Belt Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.beltName}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Belt Type:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.displayBeltType}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Position:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.position}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Belt Icon:
          </Typography>
          <div style={{ maxWidth: "80px" }}>
            <img
              width="100%"
              src={`/assets/belts/${beltLevel.beltIcon}`}
              alt="belt"
            />
          </div>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Status:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.displayStatus}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Is Default:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.isDefault ? "Yes" : "No"}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography className={classes.viewTitle} variant="caption">
            Certificate Label:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.certificateLabel}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography className={classes.viewTitle} variant="caption">
            Belt Level Description:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.description}
          </Typography>
        </Grid>
      </Grid>
      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={6}>
          <Typography className={classes.viewTitle} variant="caption">
            Last Updated:
          </Typography>
          <Typography className={classes.viewContents}>
            {displayDateUpdated}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.viewTitle} variant="caption">
            Modified By:
          </Typography>
          <Typography className={classes.viewContents}>
            {"Super Admin"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
