import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles, Snackbar } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 9999,
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

// severity="error"
// severity="warning"
// severity="info"
// severity="success"
export function SnackbarMessage({
  severity = "success",
  openStatus = false,
  //handleCloseFunction,
  message = "This is a message!",
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(openStatus);
  const { vertical, horizontal } = { vertical: 'top', horizontal: 'center',};
  const handleClose = (event, reason) => {
    
    console.log("sfsdfsdf", reason);
    
    
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin = {{vertical, horizontal}} key={vertical + horizontal}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}