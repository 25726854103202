import React from "react";
import env from "../../../env";
import { useParams } from "react-router-dom";
import utils from "../../../utils";
import { ErrorMessage, Loading } from "components";
import { variables } from "../../../cssInJs";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ChatIcon from "@material-ui/icons/Chat";
import CloseIcon from "@material-ui/icons/Close";
import * as yup from "yup";
import {
  makeStyles,
  AppBar,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  //TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  lighten,
  Toolbar,
  Backdrop,
  Checkbox,
  IconButton,
  //Tooltip,
  //ButtonGroup,
  Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  TextField,
  //FormHelperText,
  //Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
  //Grid,
  //List,
  //ListItem,
  //ListItemIcon,
  //ListItemText,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingBottom: theme.spacing(2),
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: "#fff",
    //position: "absolute",
  },
}));

const useStylesTabView = makeStyles((theme) => ({
  table: {
    //minWidth: 750,
  },
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
  formControl: {
    // width: `calc(50% - ${theme.spacing(2)}px)`,
    // display: "inline-flex",
    // margin: theme.spacing(1),
    "& button.MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      display: "none",
    },
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useCoDialogStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField : {
    width: `-webkit-fill-available`,
  }
}));

const headCells = [
  [
    {
      id: "Iamge",
      numeric: false,
      disablePadding: false,
      label: "Iamge",
      hasSort: false,
    },
    {
      id: "FirstName",
      numeric: false,
      disablePadding: false,
      label: "First Name",
      hasSort: false,
    },
    {
      id: "LastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
      hasSort: false,
    },
    {
      id: "Info",
      numeric: false,
      disablePadding: false,
      label: "Info",
      hasSort: false,
    },
    {
      id: "BeltLevel",
      numeric: false,
      disablePadding: false,
      label: "Belt Level",
      hasSort: false,
    },
    {
      id: "Age",
      numeric: false,
      disablePadding: false,
      label: "Age",
      hasSort: false,
    },
    {
      id: "Attendance",
      numeric: false,
      disablePadding: false,
      label: "Attendance",
      hasSort: false,
    },
    {
      id: "AbsentEmail",
      numeric: false,
      disablePadding: false,
      label: "Absent Email",
      hasSort: false,
    },
    {
      id: "Comment",
      numeric: false,
      disablePadding: false,
      label: "Comment",
      hasSort: false,
    },
    // {
    //   id: "Camera",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Camera",
    //   hasSort: false,
    // },
  ],
  [
    {
      id: "Iamge",
      numeric: false,
      disablePadding: false,
      label: "Iamge",
      hasSort: false,
    },
    {
      id: "FirstName",
      numeric: true,
      disablePadding: false,
      label: "First Name",
      hasSort: false,
    },
    {
      id: "LastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
      hasSort: false,
    },
    {
      id: "Info",
      numeric: false,
      disablePadding: false,
      label: "Info",
      hasSort: false,
    },
    {
      id: "RedStart",
      numeric: false,
      disablePadding: false,
      label: "Red Start",
      hasSort: false,
    },
    {
      id: "HouseTeamAward",
      numeric: false,
      disablePadding: false,
      label: "House Team Award",
      hasSort: false,
    },
    {
      id: "BlueStart",
      numeric: false,
      disablePadding: false,
      label: "Blue Start",
      hasSort: false,
    },
    {
      id: "OrangeStart",
      numeric: false,
      disablePadding: false,
      label: "Orange Start",
      hasSort: false,
    },
    {
      id: "GoldStart",
      numeric: false,
      disablePadding: false,
      label: "Gold Start",
      hasSort: false,
    },
    {
      id: "GreenStart",
      numeric: false,
      disablePadding: false,
      label: "Green Start",
      hasSort: false,
    },
    {
      id: "Home Work",
      numeric: false,
      disablePadding: false,
      label: "Home Work",
      hasSort: false,
    },
    {
      id: "SpecialBadges",
      numeric: false,
      disablePadding: false,
      label: "Special Badges",
      hasSort: false,
    },
  ],
  [
    {
      id: "Iamge",
      numeric: false,
      disablePadding: false,
      label: "Iamge",
      hasSort: false,
    },
    {
      id: "FirstName",
      numeric: true,
      disablePadding: false,
      label: "First Name",
      hasSort: false,
    },
    {
      id: "LastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
      hasSort: false,
    },
    {
      id: "Info",
      numeric: false,
      disablePadding: false,
      label: "Info",
      hasSort: false,
    },
    {
      id: "Reminder",
      numeric: false,
      disablePadding: false,
      label: "Reminder",
      hasSort: false,
    },
    {
      id: "Warning",
      numeric: false,
      disablePadding: false,
      label: "Warning",
      hasSort: false,
    },
    {
      id: "Timeout",
      numeric: false,
      disablePadding: false,
      label: "Timeout",
      hasSort: false,
    },
  ],
  [
    {
      id: "Iamge",
      numeric: false,
      disablePadding: false,
      label: "Iamge",
      hasSort: false,
    },
    {
      id: "FirstName",
      numeric: true,
      disablePadding: false,
      label: "First Name",
      hasSort: false,
    },
    {
      id: "LastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
      hasSort: false,
    },
    {
      id: "Info",
      numeric: false,
      disablePadding: false,
      label: "Info",
      hasSort: false,
    },
    {
      id: "BeltLevel",
      numeric: false,
      disablePadding: false,
      label: "Belt Level",
      hasSort: false,
    },
    {
      id: "Age",
      numeric: false,
      disablePadding: false,
      label: "Age",
      hasSort: false,
    },
    {
      id: "Attendance",
      numeric: false,
      disablePadding: false,
      label: "Attendance",
      hasSort: false,
    },
    {
      id: "BBA",
      numeric: false,
      disablePadding: false,
      label: "BBA",
      hasSort: false,
    },
    {
      id: "Upgrade",
      numeric: false,
      disablePadding: false,
      label: "Upgrade",
      hasSort: false,
    },
  ],
];

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const TabViewItem = (props) => {
  const classes = useStylesTabView();
  const {
    //value,
    headCells,
    order,
    orderBy,
    onRequestSort,
    assignClasses,
    beltLevels,
    handleBeltlevelChange,
    handleCheckAttendance,
    handleCheckAbsentEmail,
    handleOpenComment,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <TableHead className={classes.tableHead}>
          <TableRow>
            {headCells.map((headCell) => {
              if (headCell.hasSort) {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                );
              } else {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {assignClasses.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            const _img =
              row.photo && row.photo !== ""
                ? `${env.resourceBase}/media/uploadimages/${row.photo}`
                : "/assets/no-image.png";
            const _beltLevel = (beltLevels || []).find(
              (x) => x.beltLevelId === row.beltLevelId
            );

            const _attendance = row.history ? row.history.attendance : false;
            const _absentEmail = row.history ? row.history.absentEmail : false;
            const _age = utils.site.calculateAge(new Date(row.dateOfBirth));

            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.assignClassId}
              >
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                >
                  <img width="50px" src={_img} alt="belt" />
                </TableCell>
                <TableCell align="left">{row.firstName}</TableCell>
                <TableCell align="left">{row.lastName}</TableCell>
                <TableCell align="left">-</TableCell>
                <TableCell align="left">
                  <Autocomplete
                    id="beltLevelId"
                    //disabled={!checkCreateBatch}
                    options={beltLevels || []}
                    className={classes.formControl}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.beltName}
                    getOptionSelected={(option, value) =>
                      option.beltLevelId === value.beltLevelId
                    }
                    value={_beltLevel || null}
                    onChange={(event, newValue) => {
                      handleBeltlevelChange(
                        row.studentRegoId,
                        newValue ? newValue.beltLevelId : null
                      );
                    }}
                    renderOption={(option) => (
                      <React.Fragment>{option.beltName}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        variant="outlined"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </TableCell>
                <TableCell align="left">{_age}</TableCell>
                <TableCell align="left">
                  <Checkbox
                    checked={_attendance}
                    onChange={(event) => {
                      handleCheckAttendance(
                        event,
                        row.assignClassId,
                        row.history ? row.history.studetnClassHistoryId : null
                      );
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    checked={_absentEmail}
                    onChange={(event) => {
                      handleCheckAbsentEmail(
                        event,
                        row.assignClassId,
                        row.history ? row.history.studetnClassHistoryId : null
                      );
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>
                <TableCell align="left">
                  <ChatIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleOpenComment();
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>

        {/* {value == "attendance" && (
          <AttendanceTableView
            assignClasses={assignClasses}
            beltLevels={beltLevels}
            handleBeltlevelChange={handleBeltlevelChange}
            handleCheckAttendance={handleCheckAttendance}
            handleCheckAbsentEmail={handleCheckAbsentEmail}
            handleOpenComment={handleOpenComment}
          />
        )} */}
      </Table>
    </TableContainer>
  );
};

const createTab = (value, label) => {
  return { value, label };
};

const tabs = [
  createTab("attendance", "Attendance"),
  createTab("rewards", "Rewards"),
  createTab("discipline", "Discipline"),
  createTab("graduation", "Graduation"),
];

const a11yProps = (index) => {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
};

const EnhancedTableToolbar2 = (props) => {
  const classes = useToolbarStyles();
  const {
    //numSelected,
    //handleFilter,
    //currentTab,
    //activeSetupBeltSize,
    //inCctiveSetupBeltSize,
    //deleteItems,
    //studioId,
    classView,
    commit,
  } = props;

  return (
    <Toolbar className={clsx(classes.root)} style={{ minHeight: "50px" }}>
      <Box className={classes.title}>
        <Typography> Class Details </Typography>
      </Box>

      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={commit}
        //className={classes.button}
        // startIcon={
        //   isSubmitting && (
        //     <span
        //       className="spinner-grow spinner-grow-sm"
        //       role="status"
        //       aria-hidden="true"
        //     ></span>
        //   )
        // }
        // disabled={isSubmitting}
      >
        Commit
      </Button>
    </Toolbar>
  );
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    //numSelected,
    //handleFilter,
    //currentTab,
    //activeSetupBeltSize,
    //inCctiveSetupBeltSize,
    //deleteItems,
    //studioId,
    classView,
  } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Box className={classes.title}>
        <Typography> {classView.programName} </Typography>
        <Typography variant="body2">
          {`Week #${classView.week} | ${utils.site.formatDDMMYYYY(
            new Date(classView.date)
          )} | ${classView.displayDay} | ${classView.startTime} - ${
            classView.endTime
          }`}
        </Typography>
      </Box>

      {/* <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Create Belt Size" arrow>
          <Button
            component={Link}
            to={`/dashboard/property-metrics/setup-belt-size/new`}
          >
            <AddIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Active" arrow>
          <Button onClick={activeSetupBeltSize}>
            <CheckCircleOutlineIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="In Active" arrow>
          <Button onClick={inCctiveSetupBeltSize}>
            <RemoveCircleIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="Delete Studio" arrow>
          <Button onClick={deleteItems}>
            <DeleteIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup> */}
    </Toolbar>
  );
};

const CommentDialog = (props) => {
  const classes = useCoDialogStyles();
  const { open, handleCloseComment } = props;

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <form
        method="POST"
        noValidate=""
        //className={classes.formRoot}
        //onSubmit={handleSubmit(submit)}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6">Comments</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseComment}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            //id="outlined-basic"
            label="Commnet"
            variant="outlined"
            //error={!!errors.intellectualSkill2Commnet}
            //helperText={errors?.intellectualSkill2Commnet?.message}
            size="small"
            className={classes.textField}
            multiline
            minRows={2}
            //{...register("intellectualSkill2Commnet")}
          />
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            type="submit"
            // startIcon={
            //   isSubmitting && (
            //     <span
            //       className="spinner-grow spinner-grow-sm"
            //       role="status"
            //       aria-hidden="true"
            //     ></span>
            //   )
            // }
            // disabled={isSubmitting}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const schema = yup.object().shape({
  description: yup.string().required("Description is required."),
});

export function ClassDetails() {
  const classes = useStyles();
  const { studioId, classId } = useParams(); //termId
  const previousController = React.useRef();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [getCDResultModel, setGetCDResultModel] = React.useState();
  const [tab, setTab] = React.useState(tabs[0].value);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const [commentDialogInfo, setCommentDialogInfo] = React.useState({
    open: false,
  });

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const commit = async () => {
    console.log("fsdlkfjsdlkjfsdlfkjsdlfkjsdlkfj");
    setBackdropOpen(true);
  };

  const handleBeltlevelChange = async (studentRegoId, beltLevelId) => {
    if (!beltLevelId) {
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(
        env.apiBase + "/api/student/updatestudentregoforbeltlevel",
        {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studentRegoId: studentRegoId,
            beltLevelId: beltLevelId,
          }),
          ...options,
        }
      );

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      } else {
        setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  };

  const apiCall = async (url, apiData) => {
    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + url, {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...apiData,
          classId: classId,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      } else {
        setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  };

  const handleCheckAttendance = (
    event,
    assignClassId,
    studetnClassHistoryId
  ) => {
    apiCall("/api/student/updatehistoryattend", {
      attendance: event.target.checked,
      assignClassId: assignClassId,
      studetnClassHistoryId: studetnClassHistoryId,
    });
  };

  const handleCheckAbsentEmail = (
    event,
    assignClassId,
    studetnClassHistoryId
  ) => {
    apiCall("/api/student/updatehistoryabsentemail", {
      absentEmail: event.target.checked,
      assignClassId: assignClassId,
      studetnClassHistoryId: studetnClassHistoryId,
    });
  };

  const handleOpenComment = () => {
    commentDialogInfo.open = true;
    setCommentDialogInfo({
      ...commentDialogInfo,
    });
  };

  const handleCloseComment = () => {
    commentDialogInfo.open = false;
    setCommentDialogInfo({
      ...commentDialogInfo,
    });
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/classes/getclassdetails", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studioId: studioId,
            classId: classId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
          return;
        }

        if (data.results) {
          setGetCDResultModel({
            ...data.results,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }

      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <CommentDialog
        {...commentDialogInfo}
        handleCloseComment={handleCloseComment}
      />

      <Paper className={classes.paper}>
        <EnhancedTableToolbar2 {...getCDResultModel} commit={commit} />
        <hr
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            borderColor: "rgba(0,0,0,.1)",
          }}
        />
        <EnhancedTableToolbar {...getCDResultModel} />
        <AppBar position="static">
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            {tabs.map((row, index) => {
              return (
                <Tab
                  wrapped
                  key={row.value}
                  value={row.value}
                  label={row.label}
                  {...a11yProps(row.value)}
                />
              );
            })}
          </Tabs>
        </AppBar>
        {tabs.map((row, index) => {
          return (
            <TabPanel key={row.value} value={tab} index={row.value}>
              {row.value === "attendance" && (
                <TabViewItem
                  {...getCDResultModel}
                  headCells={headCells[index]}
                  handleBeltlevelChange={handleBeltlevelChange}
                  handleCheckAttendance={handleCheckAttendance}
                  handleCheckAbsentEmail={handleCheckAbsentEmail}
                  handleOpenComment={handleOpenComment}
                  handleCloseComment={handleCloseComment}
                />
              )}
            </TabPanel>
          );
        })}
      </Paper>
    </div>
  );
}
