import React from "react";
import env from "../../../env";
import { variables } from "../../../cssInJs";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
//import clsx from "clsx";
import utils from "../../../utils";
import { ErrorMessage, Loading } from "components";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import { FilePond, registerPlugin } from "react-filepond";
//import "filepond/dist/filepond.min.css";
//import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
//import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
//import FilePondPluginImageResize from "filepond-plugin-image-resize";
//import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import DateRangeIcon from "@material-ui/icons/DateRange";
//import LensIcon from "@material-ui/icons/Lens";
//import AddIcon from "@material-ui/icons/Add";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
//import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import DescriptionIcon from "@material-ui/icons/Description";
//import queryString from "query-string";
import {
  makeStyles,
  //AppBar,
  //Tab,
  //Tabs,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  //IconButton,
  //Tooltip,
  //ButtonGroup,
  Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  TextField,
  //FormHelperText,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  //DialogActions,
  Grid,
  //List,
  //ListItem,
  //ListItemIcon,
  //ListItemText,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  formRoot: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor": {
      margin: theme.spacing(1),
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },
  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },

  marginDiv: {
    margin: theme.spacing(1),
    display: "inline-block",
    //width: "-webkit-fill-available",
  },
  option: {
    fontSize: 14,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },

  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
  },
  box_criteria: {
    display: "flex",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  title_criteria: {
    flex: "1 1 100%",
  },
  button_criteria: {
    margin: theme.spacing(1),
  },

  box_field: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },

  textField_field: {
    //width: "-webkit-fill-available",
    flex: "1 1 100%",
  },

  buttonGroup_field: {
    marginLeft: theme.spacing(1),
    maxHeight: "42.38px",
  },

  filePond_field: {
    marginTop: theme.spacing(1),
  },
}));

const useVModeStyles = makeStyles((theme) => ({
  boxEditMode: {
    backgroundColor: "white",
    padding: theme.spacing(3),
  },
  hr: {
    borderColor: "rgba(0,0,0,.1)",
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  main_title: { marginBottom: theme.spacing(2) },
  viewTitle: {},
  viewContents: {},
  list: {
    "& .MuiListItemIcon-root": {
      minWidth: "20px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      width: "10px",
    },
  },
}));

const ViewMode = (props) => {
  const classes = useVModeStyles();
  const { medical, changeViewMode } = props;
  const lastUpdated = utils.site.formatDDMMYYYYWithTime(
    new Date(medical.dateUpdated)
  );

  return (
    <Box className={classes.boxEditMode}>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item style={{ flexGrow: "1" }}>
          <Typography>
            <DescriptionIcon /> Medical Condition
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              size="small"
              startIcon={<AttachFileIcon />}
              onClick={changeViewMode}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={`/dashboard/property-metrics/setup-medical-conditions`}
            >
              Back
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems={"center"}>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {medical.name}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Seq:
          </Typography>
          <Typography className={classes.viewContents}>
            {medical.seq}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Last Updated:
          </Typography>
          <Typography className={classes.viewContents}>
            {lastUpdated}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Modified By:
          </Typography>
          <Typography className={classes.viewContents}>Adrian Adams</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const schema = yup.object().shape({
  //studioId: yup.string().required("Studio is required."),
  name: yup.string().required("Name is required."),
  seq: yup.number().required("Seq is required."),
  status: yup.number().required("Status is required."),
});

export function SetupMedical() {
  const { setupExInfoId } = useParams();
  const classes = useStyles();
  const previousController = React.useRef();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [autoValue, setAutoValue] = React.useState({
    studio: null,
    status: null,
  });
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    //getValues,
    trigger,
    //control,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [getApiResult, setGetApiResult] = React.useState();
  const history = useHistory();

  const inputAutoChange = (event, id, id2, newValue, newValue2) => {
    setValue(id, newValue);
    trigger(id);
    setAutoValue({
      ...autoValue,
      [id2]: newValue2,
    });
    //setBackdropOpen(true);
    //setTrigger(trigger + 1);
  };

  const submit = async (dataModel) => {
    //setServerErrors([]);
    //setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(
        env.apiBase + "/api/setupexinfo/savesetupmedical",
        {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            setupExInfoId: setupExInfoId,
            ...dataModel,
          }),
          ...options,
        }
      );

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        //setBackdropOpen(false);
        setServerErrors(data.errors);
      } else {
        history.push(`/dashboard/property-metrics/setup-medical-conditions`);
        //window.location.reload();
      }
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      //setBackdropOpen(false);
    }
  };

  const changeViewMode = () => {
    getApiResult.editMode = false;
    setGetApiResult({ ...getApiResult });
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/setupexinfo/getsetuptmedical",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              setupExInfoId: setupExInfoId,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();
        
        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getSetuptMedicalRe) {
          let editMode = false;
          let newBatch = true;

          const _medical = data.results.getSetuptMedicalRe.medical;
          const _activeTypes = data.results.getSetuptMedicalRe.activeTypes;

          if (_medical) {
            editMode = true;
            newBatch = false;
            reset({
              name: _medical.name,
              seq: _medical.seq,
              status: _medical.status,
            });

            autoValue.status = _activeTypes.find(
              (x) => x.value === _medical.status
            );

            setAutoValue({ ...autoValue });
          }

          setGetApiResult({
            editMode,
            newBatch,
            ...data.results.getSetuptMedicalRe,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {getApiResult && getApiResult.editMode === true ? (
          <ViewMode {...getApiResult} changeViewMode={changeViewMode} />
        ) : (
          <>
            <Typography className={classes.marginDiv}>
              <DateRangeIcon /> Medical Condition
            </Typography>
            <form
              method="POST"
              noValidate=""
              className={classes.formRoot}
              onSubmit={handleSubmit(submit)}
            >
              <TextField
                //id="outlined-basic"
                label="Name*"
                variant="outlined"
                error={!!errors.name}
                helperText={errors?.name?.message}
                size="small"
                className={classes.textField}
                {...register("name")}
              />

              <TextField
                //id="outlined-basic"
                label="seq*"
                variant="outlined"
                error={!!errors.seq}
                helperText={errors?.seq?.message}
                size="small"
                className={classes.textField}
                type="number"
                {...register("seq")}
              />

              <Autocomplete
                id="status"
                //disabled={!checkCreateBatch}
                options={getApiResult.activeTypes}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                value={autoValue.status || null}
                onChange={(event, newValue) => {
                  inputAutoChange(
                    event,
                    "status",
                    "status",
                    newValue ? newValue.value : null,
                    newValue
                  );
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.name}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status*"
                    variant="outlined"
                    size="small"
                    error={!!errors.status}
                    helperText={errors?.status?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <div
                className={classes.buttonContainer}
                style={{ textAlign: "center" }}
              >
                <Button
                  variant="contained"
                  component={Link}
                  size="small"
                  to={`/dashboard/property-metrics/setup-medical-conditions`}
                  disabled={isSubmitting}
                  //color="primary"
                  //className={classes.button}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: "1rem" }}
                  //className={classes.button}
                  startIcon={
                    isSubmitting && (
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )
                  }
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        )}
      </Paper>
    </div>
  );
}
