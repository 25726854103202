import React from "react";
import {
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TextField,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  //DialogContentText,
  //Box,
  DialogActions,
  Grid,
  //Backdrop,
} from "@material-ui/core";
import { variables } from "../../../cssInJs";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link, useParams } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { Loading, ErrorMessage, SnackbarMessage } from "components";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import { yupResolver } from "@hookform/resolvers/yup";
import env from "../../../env";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import utils from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleFilter,
    studioId,
    deleteBatches,
    activeBatches,
    inActiveBatches,
    handleClickOpen,
  } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          //color="inherit"
          //variant="subtitle1"
          component="div"
          style={{ fontSize: "18px" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          //variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontSize: "18px" }}
        >
          Batches
        </Typography>
      )}

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Create Batch" arrow>
          <Button
            component={Link}
            to={`/dashboard/studio/${studioId}/batche/new`}
          >
            <AddIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Clone Batches" arrow>
          <Button onClick={handleClickOpen}>
            <FileCopyIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Active" arrow>
          <Button onClick={activeBatches}>
            <CheckCircleOutlineIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="In Active" arrow>
          <Button onClick={inActiveBatches}>
            <RemoveCircleIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Delete Batches" arrow>
          <Button onClick={deleteBatches}>
            <DeleteIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

//EnhancedTableHead
const headCells = [
  {
    id: "batchName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    hasSort: true,
  },
  {
    id: "programName",
    numeric: false,
    disablePadding: false,
    label: "Program",
    hasSort: true,
  },
  {
    id: "termName",
    numeric: false,
    disablePadding: false,
    label: "Term",
    hasSort: true,
  },
  {
    id: "day",
    numeric: false,
    disablePadding: false,
    label: "Day",
    hasSort: true,
  },
  {
    id: "startDate",
    numeric: false,
    disablePadding: false,
    label: "Date",
    hasSort: true,
  },

  {
    id: "startTime",
    numeric: false,
    disablePadding: false,
    label: "Time",
    hasSort: true,
  },
  {
    id: "noOfClass",
    numeric: false,
    disablePadding: false,
    label: "NoOfClasses",
    hasSort: true,
  },
  // { id: "info", numeric: false, disablePadding: false, label: "Info" },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    hasSort: false,
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const searchPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  textField: {
    //width: "-webkit-fill-available",
    margin: theme.spacing(1),
    width: `calc(33.3% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    width: `calc(33.3% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
  },
}));

const SearchPanel = (props) => {
  const classes = searchPanelStyles();
  const { register, terms, programs, autoValue, inputAutoChange, searchType } =
    props;
  return (
    <form
      method="POST"
      noValidate=""
      className={classes.root}
      //onSubmit={handleSubmit(submit)}
    >
      <FormControl
        className={classes.textField}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password-cp">
          Search by Name
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password-cp"
          type="text"
          inputProps={{
            //...params.inputProps,
            defaultValue: "",
            autoComplete: "off",
            //autoComplete: "new-password",
          }}
          {...register("search", {
            onChange: (e) => {
              searchType();
            },
          })}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={150}
        />
      </FormControl>

      <Autocomplete
        id="term"
        options={terms}
        className={classes.formControl}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.termName}
        value={autoValue.term}
        getOptionSelected={(option, value) =>
          option.termName === value.termName
        }
        onChange={(event, newValue) => {
          inputAutoChange(
            event,
            newValue,
            "termId",
            newValue ? newValue.termId : null,
            "term"
          );
        }}
        renderOption={(option) => (
          <React.Fragment>{option.termName}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Term"
            variant="outlined"
            size="small"
            //error={!!errors.term}
            //helperText={errors?.term?.message}
            inputProps={{
              ...params.inputProps,
              //autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />

      <Autocomplete
        id="program"
        options={programs}
        className={classes.formControl}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.programName}
        value={autoValue.program}
        getOptionSelected={(option, value) =>
          option.programName === value.programName
        }
        onChange={(event, newValue) => {
          inputAutoChange(
            event,
            newValue,
            "programId",
            newValue ? newValue.programId : null,
            "program"
          );
        }}
        renderOption={(option) => (
          <React.Fragment>{option.programName}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Program"
            variant="outlined"
            size="small"
            //error={!!errors.term}
            //helperText={errors?.term?.message}
            inputProps={{
              ...params.inputProps,
              //autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </form>
  );
};

const schema = yup.object().shape({
  search: yup.string(),
  termId: yup.string(),
  programId: yup.string(),
});

export function Batches() {
  const { studioId } = useParams(); //termId
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("BatchName");
  const [data, setData] = React.useState([]);
  const [programs, setPrograms] = React.useState();
  const [terms, setTerms] = React.useState();
  const [openDialog, setOpenDialog] = React.useState(false);
  //const [cloneBatchItems, setCloneBatchItems] = React.useState([]);
  const [cloneBatchTerms, setCloneBatchTerms] = React.useState([]);
  const [openErrorScb, setOpenErrorScb] = React.useState(false);

  const [autoValue, setAutoValue] = React.useState({
    program: null,
    term: null,
  });

  const previousController = React.useRef();
  const timerController = React.useRef();
  const [loading, setLoading] = React.useState(true);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [trigger, setTrigger] = React.useState(0);
  const {
    getValues,
    register,
    setValue,
    //formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: { search: "" },
  });

  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.batchId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    //console.log("property", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    if (data && data.length > 0) {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }
  };

  const handleChange = (event, batchId) => {
    //event.preventDefault();
    //event.stopPropagation();

    const selectedIndex = selected.indexOf(batchId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, batchId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }, 500);
  };

  const inputAutoChange = (
    event,
    newValue,
    schemaId,
    schemaValue,
    autoVauleId
  ) => {
    setValue(schemaId, schemaValue);
    setAutoValue({
      ...autoValue,
      [autoVauleId]: newValue,
    });

    setBackdropOpen(true);
    setTrigger(trigger + 1);
  };

  const updateCloneBatchTerms = (termValues) => {
    // _cloneBatchTerms.push({
    //   id : batchId,
    //   batch : batch,
    //   termValues : []
    // })
    // const _cloneBatchItem = cloneBatchItems.find((x) => x.id === id);
    // _cloneBatchItem.termValues = termValues;
    // setCloneBatchItems([...cloneBatchItems]);
    setCloneBatchTerms(termValues);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleClickOpen = () => {
    if (!selected || selected.length <= 0) {
      return;
    }
    // const _cloneBatchTerms = [];
    // selected.forEach((batchId) => {
    //   const batch = data.find((x) => x.batchId === batchId);
    //   _cloneBatchTerms.push({
    //     id: batchId,
    //     batch: batch,
    //     termValues: [],
    //   });
    // });
    // setCloneBatchItems(_cloneBatchTerms);
    setOpenDialog(true);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const getStatusIcon = (status) => {
    if (status === 1) {
      return (
        <CheckCircleOutlineIcon fontSize="small" style={{ color: "green" }} />
      );
    } else if (status === 99) {
      return <RemoveCircleIcon fontSize="small" style={{ color: "red" }} />;
    }
    return null;
  };

  async function activeBatches() {
    if (!selected || selected.length <= 0) {
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/classes/activeBatches", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          selectedBatchIds: selected,
        }),
        ...options,
      });

      if (!res.ok) {
        //throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
      } else {
        //history.push(`/dashboard/studios`);
        window.location.reload();
      }
    } catch (error) {
      console.error(error.message);
      setBackdropOpen(false);
    }
  }

  async function inActiveBatches() {
    if (!selected || selected.length <= 0) {
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/classes/inactivebatches", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          selectedBatchIds: selected,
        }),
        ...options,
      });

      if (!res.ok) {
        //throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
      } else {
        //history.push(`/dashboard/studios`);
        window.location.reload();
      }
    } catch (error) {
      console.error(error.message);
      setBackdropOpen(false);
    }
  }

  async function deleteBatches() {
    if (!selected || selected.length <= 0) {
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/classes/deletebatches", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          selectedBatchIds: selected,
        }),
        ...options,
      });

      if (!res.ok) {
        //throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
      } else {
        //history.push(`/dashboard/studios`);
        window.location.reload();
      }
    } catch (error) {
      console.error(error.message);
      setBackdropOpen(false);
    }
  }

  async function submitCloneBath() {
    setOpenErrorScb(false);

    if (cloneBatchTerms.length <= 0) {
      setOpenErrorScb(true);
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/classes/clonebatches", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          //cloneBatchItems: cloneBatchItems,
          selectedBatchIds: selected,
          cloneBatchTerms: cloneBatchTerms,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }
      const data = await res.json();
      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
        setBackdropOpen(false);
      } else {
        //history.push(`/dashboard/studios`);
        window.location.reload();
      }
    } catch (error) {
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  }

  React.useEffect(() => {
    
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/classes/getbatches", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studioId: studioId,
            page: page,
            rowsPerPage: rowsPerPage,
            order: order,
            orderBy: orderBy,
            ...getValues(),
          }),
          ...options,
        });

        if (!res.ok) {
          //throw new Error(`Response status: ${res.status}`);
        }
        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.batches) {
          setData(data.results.batches);
        }

        if (data.results && data.results.programs) {
          setPrograms(data.results.programs);
        }

        if (data.results && data.results.terms) {
          setTerms(data.results.terms);
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }

      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [trigger]);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      {openErrorScb && (
        <SnackbarMessage
          openStatus={true}
          severity="error"
          message="Please select one term to clone a batch"
        />
      )}

      <SimpleDialog
        openDialog={openDialog}
        handleClose={handleClose}
        selected={selected}
        terms={terms}
        cloneBatchTerms={cloneBatchTerms}
        updateCloneBatchTerms={updateCloneBatchTerms}
        submitCloneBath={submitCloneBath}
        data={data}
      />

      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleFilter={handleFilter}
          studioId={studioId}
          deleteBatches={deleteBatches}
          activeBatches={activeBatches}
          inActiveBatches={inActiveBatches}
          handleClickOpen={handleClickOpen}
        />
        {filterOpen && (
          <SearchPanel
            register={register}
            programs={programs}
            terms={terms}
            autoValue={autoValue}
            inputAutoChange={inputAutoChange}
            searchType={searchType}
          />
        )}
        {data && (
          <>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={data.length}
                />

                <TableBody>
                  {data.map((row, index) => {
                    const isItemSelected = isSelected(row.batchId);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const statusIcon = getStatusIcon(row.status);
                    const displayDate = `${utils.site.formatDDMMYYYY(
                      new Date(row.startDate)
                    )} - ${utils.site.formatDDMMYYYY(new Date(row.endDate))}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.batchId}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onChange={(event) =>
                              handleChange(event, row.batchId)
                            }
                          />
                        </TableCell>

                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <Link
                            to={`/dashboard/studio/${studioId}/batche/${row.batchId}`}
                          >
                            {row.batchName}
                          </Link>
                        </TableCell>
                        <TableCell align="left">{row.programName}</TableCell>
                        <TableCell align="left">{row.termName}</TableCell>
                        <TableCell align="left">{row.displayDay}</TableCell>
                        <TableCell align="left">{displayDate}</TableCell>
                        <TableCell align="left">{row.displayTime}</TableCell>
                        <TableCell align="left">{row.noOfClass}</TableCell>
                        {/* <TableCell align="left"></TableCell> */}
                        <TableCell align="left">
                          {statusIcon && (
                            <Tooltip title={"Active"} arrow placement="top">
                              {statusIcon}
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              //count={data.length}
              count={data && data.length > 0 ? data[0].totalCount : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>
    </div>
  );
}

const useDialogStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  autocomplete : {
    marginBottom : theme.spacing(2)
  }
}));

function SimpleDialog(props) {
  const {
    handleClose,
    openDialog,
    terms,
    cloneBatchTerms,
    selected,
    updateCloneBatchTerms,
    submitCloneBath,
    data,
  } = props;
  const classes = useDialogStyles();

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={openDialog}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        Create Clone
      </DialogTitle>

      <DialogContent dividers={true}>
        
        <Autocomplete
          id="cloneBathTerm"
          className={classes.autocomplete}
          multiple
          options={terms}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option.termName}
          getOptionSelected={(option, value) =>
            option.termName === value.termName
          }
          renderOption={(option) => (
            <React.Fragment>{option.termName}</React.Fragment>
          )}
          value={cloneBatchTerms}
          onChange={(event, newValue) => {
            updateCloneBatchTerms(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Term*"
              variant="outlined"
              size="small"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />

        {selected.map((id, index) => {
          const _batch = data.find((x) => x.batchId === id);
          return (
            <CloneBath
              key={_batch.batchId}
              id={_batch.batchId}
              batch={_batch}
              //terms={terms}
              //termValues={cloneBatchItem.termValues}
              //updateCloneBatchTerms={updateCloneBatchTerms}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={submitCloneBath}>
          Submit
        </Button>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useCloneBatchStyles = makeStyles((theme) => ({
  //box: {},
  viewTitle: {},
  viewContents: {},
  hr: {
    marginBottom: "0",
    marginTop: theme.spacing(1),
    borderColor: "rgba(0,0,0,.1)",
  },
  option: {},
}));

function CloneBath(props) {
  //const { batch, terms, termValues, updateCloneBatchTerms, id } = props;
  const { batch } = props;
  const classes = useCloneBatchStyles();

  return (
    <Grid container spacing={3} alignItems={"center"}>
      <Grid item xs={12}>
        Batch Name : {batch.batchName}
        <hr className={classes.hr} />
      </Grid>

      <Grid item xs={6}>
        <Typography className={classes.viewTitle} variant="caption">
          Program
        </Typography>
        <Typography className={classes.viewContents}>
          {batch.programName}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography className={classes.viewTitle} variant="caption">
          Day
        </Typography>
        <Typography className={classes.viewContents}>
          {batch.displayDay}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography className={classes.viewTitle} variant="caption">
          Timings
        </Typography>
        <Typography className={classes.viewContents}>
          {batch.displayTime}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography className={classes.viewTitle} variant="caption">
          Student Limit
        </Typography>
        <Typography className={classes.viewContents}>
          {batch.studentLimit}
        </Typography>
      </Grid>
    </Grid>
  );
}