import variables from "./cssInJs/variables";
import { getIdToken } from './auth/services/authHelper';

const cssEmotion = {
  breakpointNames: variables.breakpointNames,
  breakpoints: variables.breakpoints,
};

const firebaseSettings = {
  apiKey: "AIzaSyAi0t-gVgvysYwa-6xCTiCKO94ftgTSPww",
  authDomain: "masa-app-94e16.firebaseapp.com",
  projectId: "masa-app-94e16",
  storageBucket: "masa-app-94e16.appspot.com",
  messagingSenderId: "790508921644",
  appId: "1:790508921644:web:3bf31eef35e817edbd0289"
};

const apiNetJwt = async (options) => {
  const token = await getIdToken();
  //console.log(token)
  if (token) {
    options.headers = options.headers || {};
    options.headers["Authorization"] = `Bearer ${token}`;
  }
};

export default {
  cssEmotion,
  firebaseSettings,
  apiNetJwt,
};