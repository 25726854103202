import React from "react";
//import { useAuth } from "auth";
import { useRouter, ErrorMessage } from "components";
import env from "../../../env";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { variables } from "../../../cssInJs";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
//import config from "config";

import {
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  Container,
  Button,
  TextField,
  //Checkbox,
  //FormControlLabel,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    //position: "absolute",
    //left: "50%",
    //top: "50%",
    //transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "1px 1px 5px 0px #e0e0e0",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  container2: {
    position: "absolute",
    left: "50%",
    top: "45%",
    transform: "translate(-50%, -50%)",
    width: "90%",
  },
  root: {
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  button: {
    //margin: theme.spacing(1),
    width: "100%",
  },
  textField: {
    width: "-webkit-fill-available",
  },
  bg: {
    backgroundImage: `url("/assets/login-bg.7771fd96dfb79595c3b4.png")`,
    backgroundPosition: "center",
    position: "absolute",
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",
  },
  // formControl: {
  //   //margin: theme.spacing(1),
  // },
}));

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  email: yup.string().required("Email is required.").email("Email is required"),
  password: yup
    .string()
    .required("Password is required.")
    .min(6, "Password is too short - should be 6 chars minimum."),
  confirmPassword: yup
    .string()
    .required("Confirm password is required.")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  //.min(6, "Password is too short - should be 6 chars minimum."),
});

export function Signup(props) {
  //const auth = useAuth();
  const [serverErrors, setServerErrors] = React.useState([]);
  const {
    register,
    handleSubmit,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({ resolver: yupResolver(schema) });

  const classes = useStyles();
  const { history } = useRouter();

  const [showPassword, setShowPassword] = React.useState({
    p: false,
    cp: false,
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword({ ...showPassword, p: !showPassword.p });
  };

  const handleClickShowPasswordCp = () => {
    setShowPassword({ ...showPassword, cp: !showPassword.cp });
  };

  async function submit(dataModel) {
    const abortController = new AbortController();
    const signal = abortController.signal;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      //await config.apiNetJwt(options);

      const res = await fetch(env.apiBase + "/api/profile/signup", {
        method: "post",
        signal: signal,
        body: JSON.stringify(dataModel),
        ...options,
      });

      if (!res.ok) {
        //throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();
      console.log("data", data.errors);

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      }
    } catch (error) {
      console.error(error.message);
    }

    // auth
    //   .signin()
    //   .then((user) => {
    //     //console.log(user);
    //     //setPending(false);
    //     //setError("");
    //     //router.push("/member/dashboard");
    //   })
    //   .catch((error) => {
    //     //console.log(error);
    //     //setPending(false);
    //     //setError(error.message);
    //   });
  }

  return (
    <>
      <div className={classes.container2}>
        <div style={{ textAlign: "center", marginBottom: "1.5rem" }}>
          <img src="/assets/logos/logo.jpg" width="103px" alt="logo" />
        </div>

        <Container maxWidth="sm" className={classes.container}>
          <h3 style={{ textAlign: "center" }}>Studio Admin Sign Up</h3>
          <form
            method="POST"
            noValidate=""
            onSubmit={handleSubmit(submit)}
            className={classes.root}
          >
            <TextField
              //id="outlined-basic"
              label="First Name"
              variant="outlined"
              error={!!errors.firstName}
              helperText={errors?.firstName?.message}
              size="small"
              {...register("firstName")}
            />

            <TextField
              //id="outlined-basic"
              label="Last Name"
              variant="outlined"
              error={!!errors.lastName}
              helperText={errors?.lastName?.message}
              size="small"
              {...register("lastName")}
            />

            <TextField
              //id="outlined-basic"
              label="Email"
              variant="outlined"
              error={!!errors.email}
              helperText={errors?.email?.message}
              size="small"
              {...register("email")}
            />

            <FormControl
              className={classes.textField}
              variant="outlined"
              size="small"
              error={!!errors.password}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword.p ? "text" : "password"}
                {...register("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword.p ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={80}
              />
              {!!errors.password && (
                <FormHelperText error id="accountId-error">
                  {errors?.password?.message}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              variant="outlined"
              size="small"
              error={!!errors.confirmPassword}
            >
              <InputLabel htmlFor="outlined-adornment-password-cp">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-cp"
                type={showPassword.cp ? "text" : "password"}
                {...register("confirmPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordCp}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword.cp ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={150}
              />
              {!!errors.confirmPassword && (
                <FormHelperText error id="accountId-error">
                  {errors?.confirmPassword?.message}
                </FormHelperText>
              )}
            </FormControl>

            <div>
              <Button
                variant="contained"
                color="primary"
                //size="large"
                className={classes.button}
                startIcon={
                  isSubmitting ? (
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <ExitToAppOutlinedIcon />
                  )
                }
                type="submit"
                disabled={isSubmitting}
              >
                Sign up
              </Button>
            </div>
          </form>
          {serverErrors && serverErrors.length > 0 && (
            <ErrorMessage errors={serverErrors} />
          )}
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <Button
              style={{ fontWeight: 600 }}
              color="primary"
              href="/login"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push("/login");
              }}
            >
              Sign In
            </Button>
            {/* <SiteLink to={"/member/forgotPassword"}> Studio Admin Sign Up </SiteLink> */}
          </div>
        </Container>
      </div>
      <div className={classes.bg} />
    </>
  );
}
