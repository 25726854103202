import React from "react";
import env from "../../../env";
import { Link, useLocation, useParams } from "react-router-dom";
import utils from "../../../utils";
import { ErrorMessage, Loading } from "components";
import LensIcon from "@material-ui/icons/Lens";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import queryString from "query-string";
import {
  makeStyles,
  AppBar,
  Tab,
  Tabs,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  //IconButton,
  //Tooltip,
  //ButtonGroup,
  Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  //TextField,
  //FormHelperText,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  //DialogActions,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  }

}));

const useGVModeStyles = makeStyles((theme) => ({
  boxEditMode: {
    backgroundColor: "white",
    padding: theme.spacing(1),
  },
  hr: {
    borderColor: "rgba(0,0,0,.1)",
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  main_title: { marginBottom: theme.spacing(2) },
  viewTitle: {},
  viewContents: {},
  list: {
    "& .MuiListItemIcon-root": {
      minWidth: "20px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      width: "10px",
    },
  },
}));

const GetViewMode = (props) => {
  const classes = useGVModeStyles();
  const { getSetupAwardResult, awardtabtype, studioId } = props;
  const { setupAward } = getSetupAwardResult;
  const displayDateUpdated = utils.site.formatDDMMYYYYWithTime(
    new Date(setupAward.dateUpdated)
  );

  return (
    <Box className={classes.boxEditMode}>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item style={{ flexGrow: "1" }}>
          <Typography>
            <DescriptionIcon /> Awards
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={`/dashboard/studio/${studioId}/awards?awardtabtype=${awardtabtype}`}
            >
              Back
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {setupAward.awardName}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Award Type:
          </Typography>
          <Typography className={classes.viewContents}>
            {setupAward.displayAwardType}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Earning Point:
          </Typography>
          <Typography className={classes.viewContents}>
            {setupAward.earningPoint}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography
            className={classes.viewTitle}
            //variant="caption"
          >
            Criteria
          </Typography>
          <List dense={true} className={classes.list}>
            {setupAward.setupAwardCriteria &&
              setupAward.setupAwardCriteria.map((row, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <LensIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={row.title} />
                  </ListItem>
                );
              })}
          </List>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Status:
          </Typography>
          <Typography className={classes.viewContents}>
            {setupAward.awardName}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Last Updated:
          </Typography>
          <Typography className={classes.viewContents}>
            {displayDateUpdated}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Modified By:
          </Typography>
          <Typography className={classes.viewContents}>
            {setupAward.modifiedBy}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

export function Award() {
  const { studioId, awardId } = useParams();
  const classes = useStyles();
  const location = useLocation();
  const { awardtabtype } = queryString.parse(location.search);
  //const history = useHistory();
  const previousController = React.useRef();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [getSetupAwardResult, setGetSetupAwardResult] = React.useState();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/award/getsetupaward", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            setupAwardId: awardId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getSetupAwardResult) {
          let editMode = false;
          let newBatch = true;

          const _setupAward = data.results.getSetupAwardResult.setupAward;
          //const _awardTypeList = data.results.getSetupAwardResult.awardTypeList;

          if (_setupAward) {
            editMode = true;
            newBatch = false;
          }

          setGetSetupAwardResult({
            editMode,
            newBatch,
            ...data.results.getSetupAwardResult,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>

      <Paper className={classes.paper}>
        <AppBar position="static">
          <Tabs
            value={awardtabtype}
            //onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            {getSetupAwardResult.awardTabList.map((awardTab, index) => {
              return (
                <Tab
                  key={awardTab.value}
                  value={awardTab.value + ""}
                  label={awardTab.name}
                  {...a11yProps(awardTab.value)}
                  component={Link}
                  to={`/dashboard/property-metrics/setup-awards?awardtabtype=${awardTab.value}`}
                />
              );
            })}
          </Tabs>
        </AppBar>
        <GetViewMode
          getSetupAwardResult={getSetupAwardResult}
          awardtabtype={awardtabtype}
          studioId = {studioId}
        />
      </Paper>
    </div>
  );
}