import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles, Snackbar } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 9999,
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

// severity="error"
// severity="warning"
// severity="info"
// severity="success"

// severity = "success",
//   openStatus = false,
//   handleCloseFunction,
//   message = "This is a message!",

export function SnackbarMessageV2(props) {
  
  const {
    severity,
    openStatus,
    message,
    handleCloseFunction
  } = props;
  
  const { vertical, horizontal } = { vertical: "top", horizontal: "center" };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleCloseFunction();
  };

  return (
    // <div className={classes.root}>
    // </div>
    <Snackbar
      open={openStatus}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
