import React from "react";
import env from "../../../env";
import { Link, useParams, useLocation } from "react-router-dom";
import queryString from "query-string";
import { Loading } from "components";
import { variables } from "../../../cssInJs";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoIcon from "@material-ui/icons/Info";
import SearchIcon from "@material-ui/icons/Search";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AppBar,
  Tab,
  Tabs,
  //Toolbar,
  //lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  //TablePagination,
  TableRow,
  TableSortLabel,
  //Typography,
  Paper,
  //Checkbox,
  IconButton,
  Tooltip,
  //ButtonGroup,
  //Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  //TextField,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  //Box,
  //DialogActions,
  //Grid,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const useSPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    //marginBottom: theme.spacing(2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
}));

const getStatusIcon = (status) => {
  if (status === 1) {
    return (
      <CheckCircleOutlineIcon fontSize="small" style={{ color: "green" }} />
    );
  } else if (status === 99) {
    return <RemoveCircleIcon fontSize="small" style={{ color: "red" }} />;
  }
  return null;
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

//EnhancedTableHead
const headCells = [
  {
    id: "BeltName",
    numeric: false,
    disablePadding: false,
    label: "Belt Name",
    hasSort: true,
  },
  {
    id: "BeltType",
    numeric: false,
    disablePadding: false,
    label: "Belt Type",
    hasSort: false,
  },
  {
    id: "BeltIcon",
    numeric: false,
    disablePadding: false,
    label: "Belt Icon",
    hasSort: true,
  },
  {
    id: "IsDefault",
    numeric: false,
    disablePadding: false,
    label: "Is Default",
    hasSort: true,
  },
  {
    id: "Position",
    numeric: false,
    disablePadding: false,
    label: "Position",
    hasSort: true,
  },

  {
    id: "info",
    numeric: false,
    disablePadding: false,
    label: "Info",
    hasSort: false,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    hasSort: false,
  },
];

function EnhancedTableHead(props) {
  const {
    //onSelectAllClick,
    order,
    orderBy,
    //numSelected,
    //rowCount,
    onRequestSort,
  } = props;
  const classes = useETHeadStyles();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}

const SearchPanel = (props) => {
  const classes = useSPanelStyles();
  const { register, searchType } = props;
  return (
    <form method="POST" noValidate="" className={classes.root}>
      <FormControl
        //className={classes.textField}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password-cp">
          Search by Belt name
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password-cp"
          type="text"
          inputProps={{
            //...params.inputProps,
            defaultValue: "",
            autoComplete: "off",
            //autoComplete: "new-password",
          }}
          {...register("search", {
            onChange: (e) => {
              searchType();
            },
          })}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={150}
        />
      </FormControl>
    </form>
  );
};

const schema = yup.object().shape({
  search: yup.string(),
});

export function BeltLevels() {
  const { studioId } = useParams(); //termId
  const location = useLocation();
  const { programIdParm } = queryString.parse(location.search);
  const previousController = React.useRef();
  const timerController = React.useRef();
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [getBeltLevelsResult, setGetBeltLevelsResult] = React.useState();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  const [currentTab, setCurrentTab] = React.useState(() => {
    if (programIdParm) {
      return programIdParm;
    }
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Position");
  const [trigger, setTrigger] = React.useState(0);
  const {
    getValues,
    register,
    setValue,
    //formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: { search: "" },
  });

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
    setOrder("asc");
    setOrderBy("Position");
    setValue("search", "");

    setBackdropOpen(true);
    setTrigger(trigger + 1);
  };

  const handleRequestSort = (event, property) => {
    //console.log("property", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    if (
      getBeltLevelsResult &&
      getBeltLevelsResult.beltLevels &&
      getBeltLevelsResult.beltLevels.length > 0
    ) {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }
  };

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }, 500);
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/belt/getbeltlevels", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studioId: studioId,
            programId: currentTab,
            order: order,
            orderBy: orderBy,
            ...getValues(),
          }),
          ...options,
        });

        if (!res.ok) {
          //throw new Error(`Response status: ${res.status}`);
        }
        const data = await res.json();
        
        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getBeltLevelsResult) {
          setGetBeltLevelsResult(data.results.getBeltLevelsResult);
          setCurrentTab(data.results.getBeltLevelsResult.programId);
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [trigger]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Paper className={classes.paper}>
        <AppBar position="static">
          <Tabs
            value={currentTab}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            {getBeltLevelsResult.programs.map((program, index) => {
              return (
                <Tab
                  key={program.programId}
                  value={program.programId}
                  label={program.programName}
                  wrapped
                  {...a11yProps(program.programId)}
                />
              );
            })}
          </Tabs>
        </AppBar>
        <SearchPanel register={register} searchType={searchType} />
        {getBeltLevelsResult.beltLevels && (
          <>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {getBeltLevelsResult.beltLevels.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const statusIcon = getStatusIcon(row.status);
                    const _img =
                      row.beltIcon && row.beltIcon !== ""
                        ? `${env.resourceBase}/media/uploadimages/${row.beltIcon}`
                        : "/assets/no-image.png";
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.beltLevelId}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          //padding="none"
                        >
                          <Link
                            to={`/dashboard/studio/${studioId}/beltLevel/${row.beltLevelId}`}
                          >
                            {row.beltName}
                          </Link>
                        </TableCell>
                        <TableCell align="left">{row.beltType}</TableCell>
                        <TableCell align="left">
                          <img width="50px" src={_img} alt="belt" />
                        </TableCell>
                        <TableCell align="left">
                          {row.isDefault ? "Yes" : "No"}
                        </TableCell>
                        <TableCell align="left">{row.position}</TableCell>
                        <TableCell align="left">
                          <InfoIcon />
                        </TableCell>
                        <TableCell align="left">
                          {statusIcon && (
                            <Tooltip title={"Active"} arrow placement="top">
                              {statusIcon}
                            </Tooltip>
                          )}{" "}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Paper>
    </div>
  );
}
